import React from 'react';

import TextInput from './TextInput.js';
import CheckboxInput from './CheckboxInput.js';

import '../../sass/components/input/TodoList.scss';

export default class TodoList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  addItem = () => {
    this.handleChange([
      ...this.props.object || [],
      { content: '', checked: false },
    ])
  }

  updateItem = (index, key, value) => {
    const object = this.props.object || [];
    if (index > -1) {
      let updated = object.slice();
      updated.splice(index, 1, {
        ...object[index],
        [key]: value
      });
      this.handleChange(updated);
    }
  }

  deleteItem = (index) => {
    const object = this.props.object || [];
    if (index > -1) {
      let updated = object.slice();
      updated.splice(index, 1);
      this.handleChange(updated);
    }
  }

  renderListItem = (item, index) => {
    const properties = this.props.properties;
    return (
      <div key={index} className='listItem'>
        {properties.disableCheck ? null :
          <CheckboxInput
            object={item.checked}
            onChange={(k, value) => this.updateItem(index, 'checked', value)}
          />
        }
        <TextInput
          properties={{type: 'text'}}
          object={item.content}
          disabled={properties.disableEdit}
          onChange={(k, value) => this.updateItem(index, 'content', value)}
        />
        {properties.disableEdit ? null :
          <div
            className='removeButton'
            onClick={() => this.deleteItem(index)}
          >
            <span className='material-icons'>close</span>
          </div>
        }
      </div>
    )
  }

  render = () => {
    const object = this.props.object || [];
    return (
      <div className='todoList'>
        <div className='listContainer'>
          {object.map(this.renderListItem)}
        </div>
        {this.props.properties.disableEdit ? null :
          <button
            className='outline'
            onClick={this.addItem}
          >Add</button>
        }
      </div>
    )
  }

}
