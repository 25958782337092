import React from 'react';

import '../../sass/components/input/FileInput.scss';

export default class FileInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      preview: props.object,
    };
    this.input = null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.object !== this.props.object) {
      if (typeof this.props.object === 'string') {
        this.setState({preview: this.props.object});
      } else if (this.props.object === null) {
        this.setState({preview: null});
      }
    }
  }

  handleChange = (value) => {
    this.setState({preview: value});
    this.props.onChange(this.props.objectKey, value);
  }

  componentDidMount = () => {
  }

  render = () => {
    const preview = this.state.preview;
    const multiple = this.props.properties?.multiple;
    return (
      <div className='fileInput'>
        <input
          type='file'
          accept={this.props.properties?.accept || '*'}
          ref={input => this.input = input}
          onChange={e => this.handleChange(multiple ? e.target.files : e.target.files[0])}
          multiple={multiple}
        />
        {preview ?
          multiple
            ? <span>Выбрано файлов: {preview.length}</span>
            : preview.name
              ? <span>{preview.name}</span>
              : <a href={preview} target='_blank' rel='noopener noreferrer'>{preview}</a>
        : null}
        {this.props.disabled ? null :
          <button
            className='outline'
            onClick={() => this.input.click()}
          >Выбрать файл{multiple ? 'ы': ''}</button>
        }
      </div>
    )
  }
}
