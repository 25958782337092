import React from 'react';
import classnames from 'classnames';

import FlexibleInput from './FlexibleInput';

import '../../sass/components/common/ObjectEditInput.scss';

export default class ObjectEditInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  render = () => {
    const { inputKey, object, error, inputProperties, readOnly } = this.props;

    if (!inputProperties) {
      return null;
    }
    if (!inputProperties.type || ['none', 'avatar'].indexOf(inputProperties.type) > -1) {
      return null;
    }
    if (inputProperties.showCondition && !inputProperties.showCondition(object)) {
      return null;
    }
    let groupHidden = inputProperties.preset && !object.id;
    let inputDisabled = inputProperties.preset;
    if (inputProperties.createOnly && object.id) {
      inputDisabled = true;
    }
    if (inputProperties.modifyCondition && !inputProperties.modifyCondition(object)) {
      inputDisabled = true;
    }
    if (readOnly || object.deleted) {
      inputDisabled = true;
    }
    let labelTitle = inputProperties.title;
    if (inputProperties.required) {
      labelTitle += ' *';
    }
    return (
      <div
        key={inputKey}
        id={`input-${inputKey}`}
        className={classnames({
          'objectEditInput': true,
          'hidden': groupHidden,
        })}
      >
        {labelTitle ?
          <label>
            {inputProperties.tip ?
              <div className='tip'>
                ?
                <div className='tooltip nowrap'>{inputProperties.tip}</div>
              </div>
            : null}
            {labelTitle}
          </label>
        : null}
        <div className='inputElem'>
          <FlexibleInput
            object={object[inputKey]}
            parentObject={object}
            onChange={this.props.onChange}
            disabled={inputDisabled}
            objectKey={inputKey}
            properties={inputProperties}
          />
          {error ?
            <div className='inputError'>{error}</div>
          : null}
        </div>
      </div>
    );
  }
}
