import React from 'react';
import { withRouter } from 'react-router-dom';

import Storage from '../helpers/Storage';
import { sendRequest } from '../helpers/global.js';

import TextInput from './input/TextInput.js';

import '../sass/components/LoginView.scss';

class LoginView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {
        login: '',
        password: '',
      },
      errors: {},
    };
  }

  loginAction = (data) => {
    localStorage.setItem('meter-inspector-admin:token', data.access_token);
    Storage.setData('user', data.user);
  }

  handleSubmit = () => {
    let data = this.state.data;
    let errors = {};
    if (!data.login) {
      errors.login = 'Пожалуйста введите логин';
    }
    if (!data.password) {
      errors.password = 'Пожалуйста введите пароль';
    }
    if (Object.keys(errors).length > 0) {
      this.setState({errors});
      return;
    }
    sendRequest({
      method: 'auth/login',
      auth: true,
      type: 'POST',
      data: {
        login: data.login,
        password: data.password,
        app: 'admin',
        user_agent: window.navigator.userAgent,
      },
      success: (data) => {
        if (data.access_token) {
          this.loginAction(data);
        }
      },
      error: (data) => {
        if (data && data.errors) {
          this.setState({errors: data.errors});
        }
      }
    });
  }

  renderInput = (key, title, type = 'text') => {
    return (
      <div key={key}>
        <label htmlFor={key}>{title}</label>
        <TextInput
          properties={{type}}
          objectKey={key}
          object={this.state.data[key] || ''}
          onChange={(k, val) => this.setState({data: {...this.state.data, [key]: val}})}
          onFocus={() => this.setState({errors: {...this.state.errors, [key]: null}})}
          error={this.state.errors[key]}
        />
      </div>
    )
  }

  render = () => {
    return (
      <div className='loginView'>
        <div className='loginForm'>
          <div className='logo'>Мобильный контролер</div>
          <div className='loginTitle'>Панель управления</div>
          <div className='loginSubtitle'>Вход в учетную запись</div>
          {this.renderInput('login', 'Логин', 'text')}
          {this.renderInput('password', 'Пароль', 'password')}
          <button
            onClick={this.handleSubmit}
          >Вход</button>
          {/*<div className='bottomRow'>
            Don’t have a business account?
            <span
              onClick={() => this.props.history.push('/register')}
            >Sign up</span>
          </div>*/}
          {/*<div className='bottomRow'>
            <span
              onClick={() => this.props.history.push('/forgot_password')}
            >Забыли пароль?</span>
          </div>*/}
        </div>
      </div>
    );
  }
}

export default withRouter(LoginView);
