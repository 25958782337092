import React from 'react';
import { withRouter } from 'react-router-dom';

import { sendRequest, triggerEvent } from '../../helpers/global';

import FileInput from '../input/FileInput';

import '../../sass/components/AdminToolsView.scss';

class TNSImportTasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: null,
    }
  }

  onImportTasks = () => {
    const { files } = this.state;
    const formData = new FormData();
    [...files].forEach(file => {
      formData.append('files[]', file);
    });
    sendRequest({
      method: 'import/tns',
      type: 'POST',
      formData,
      success: (data) => {
        this.setState({files: null})
        triggerEvent('showSnackbar', [{text: 'Записи в обработке', type: 'success'}]);
      },
      error: (data) => {
      }
    });
  }

  render = () => {
    const { files } = this.state;
    const dataFilled = files?.length;
    return (
      <div className='toolSection'>
        <div className='toolName'>Импорт заданий от ТНС Энерго</div>
        <FileInput
          properties={{
            accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            multiple: true,
          }}
          object={files}
          onChange={(k, value) => this.setState({files: value})}
        />
        <button
          disabled={!dataFilled}
          onClick={this.onImportTasks}
        >Загрузить</button>
      </div>
    )
  }
}

export default (withRouter(TNSImportTasks));
