import React from 'react';
import { withRouter } from 'react-router-dom';

import { sendRequest, triggerEvent, TIME_OPTIONS } from '../../helpers/global';

import FileInput from '../input/FileInput';
import SelectInput from '../input/SelectInput';
import DateInput from '../input/DateInput';

import '../../sass/components/AdminToolsView.scss';

class KSKImportTasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inspector_id: null,
      start_at: null,
      time_from: null,
      time_to: null,
      files: null,
      inspectors: [],
    }
  }

  componentDidMount = () => {
    this.requestInspectors();
  }

  requestInspectors = () => {
    sendRequest({
      method: 'common/inspectors',
      type: 'GET',
      success: (data) => {
        this.setState({ inspectors: (data || []) });
      },
      error: (data) => {
      }
    });
  }

  onImportTasks = () => {
    const { inspector_id, start_at, time_from, time_to, files } = this.state;
    const formData = new FormData();
    formData.append('inspector_id', inspector_id);
    if (start_at) {
      formData.append('start_at', start_at);
    }
    if (time_from) {
      formData.append('time_from', time_from);
    }
    if (time_to) {
      formData.append('time_to', time_to);
    }
    [...files].forEach(file => {
      formData.append('files[]', file);
    });
    sendRequest({
      method: 'import/ksk',
      type: 'POST',
      formData,
      success: (data) => {
        this.setState({files: null})
        triggerEvent('showSnackbar', [{text: 'Записи в обработке', type: 'success'}]);
      },
      error: (data) => {
      }
    });
  }

  render = () => {
    const { inspector_id, start_at, time_from, time_to, files } = this.state;
    const dataFilled = inspector_id && files?.length && (!start_at || (time_from && time_to));
    const group_id = this.props.group_id;
    const filteredInspectors = this.state.inspectors.filter(i => i.group_id === group_id);
    return (
      <div className='toolSection'>
        <div className='toolName'>Импорт заданий от КСК</div>
        <SelectInput
          properties={{
            placeholder: 'Инспектор',
            options: filteredInspectors,
          }}
          object={inspector_id}
          onChange={(k, value) => this.setState({inspector_id: value})}
        />
        <DateInput
          properties={{
            type: 'date',
            clearable: true,
          }}
          placeholder='Дата проверки'
          object={start_at}
          onChange={(k, value) => this.setState({
            start_at: value,
            time_from: value ? time_from : null,
            time_to: value ? time_to : null,
          })}
        />
        {start_at ?
          <div className='dateRange'>
            <SelectInput
              properties={{
                options: TIME_OPTIONS.slice(0,-1),
                placeholder: 'Начало',
              }}
              object={time_from}
              onChange={(k, value) => this.setState({
                time_from: value,
                time_to: time_to <= value ? null : time_to,
              })}
            />
            <label> - </label>
            <SelectInput
              properties={{
                options: TIME_OPTIONS.filter(i => i.id > time_from),
                placeholder: 'Конец',
              }}
              object={time_to}
              onChange={(k, value) => this.setState({time_to: value})}
            />
          </div>
        : null}
        <FileInput
          properties={{
            accept: '.csv',
            multiple: true,
          }}
          object={files}
          onChange={(k, value) => this.setState({files: value})}
        />
        <button
          disabled={!dataFilled}
          onClick={this.onImportTasks}
        >Загрузить</button>
      </div>
    )
  }
}

export default (withRouter(KSKImportTasks));
