import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { saveAs } from 'file-saver';

import appConfig from '../config/';
import { sendRequest } from '../helpers/global.js';

import SearchView from './SearchView';
import GraphListView from './common/GraphListView';

import '../sass/components/DashboardView.scss';

class DashboardView extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props)
  }

  getDefaultState = (props) => {
    const properties = appConfig[props.configKey].properties;
    const config = appConfig[props.configKey].config;
    let search = {};
    // Default search interval
    if (config.search) {
      Object.keys(config.search).forEach((key) => {
        search[key] = config.search[key].default || null;
      });
    }
    return {
      data: {},
      properties,
      config, 
      search,
      searchOpen: false,
    };
  }

  componentDidMount = () => {
    this.requestData();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.configKey !== prevProps.configKey) {
      this.setState(this.getDefaultState(this.props), () => {
        this.requestData();
      })
    }
  }

  requestData = () => {
    sendRequest({
      type: 'GET',
      method: 'dashboard',
      data: {
        ...this.state.search,
      },
      success: (data) => {
        this.setState({data});
      },
      error: (xhr, status, err) => {
      }
    });
  }

  onSearch = (search) => {
    let data = {...this.state.search};
    Object.keys(search).forEach(key => {
      if (search[key] === undefined || search[key] === null) {
        data[key] = null;
      } else {
        data[key] = search[key];
      }
    });
    this.setState({
      search: data,
      searchOpen: false,
    }, () => {
      this.requestData();
    });
  }

  requestCsvData = (search) => {
    sendRequest({
      method: this.state.config.exportMethod,
      data: search,
      type: 'GET',
      success: (data) => {
        const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const title = this.state.config.title;
        const titleFormatted = title ? title.toLowerCase().replace(/ /g, '_') : null;
        const fileName = titleFormatted ? `${titleFormatted}.csv` : 'search.csv';
        saveAs(csvData, fileName);
      },
      error: (data) => {
      }
    });
  }

  renderSearch = () => {
    if (this.state.config.search || this.state.config.exportMethod) {
      return (
        <>
          <div
            className='filterButton'
            onClick={() => this.setState({searchOpen: !this.state.searchOpen})}
          >
            <span className='material-icons'>filter_list</span>
          </div>
          <div
            className={classnames('searchSidebar', {
              'open': this.state.searchOpen,
            })}
            onClick={e => {
              if (e.target === e.currentTarget) {
                this.setState({searchOpen: false});
              }
            }}
          >
            <SearchView
              initialValue={this.state.search}
              config={this.state.config.search || {}}
              onSearch={this.onSearch}
              onExport={this.state.config.exportMethod ?
                search => this.requestCsvData(search)
              : null}
            />    
          </div>
        </>
      )
    }
    return null;
  }

  renderGraph = (key) => {
    const itemConfig = this.state.properties[key];
    const itemData = this.state.data[key];
    if (!itemData) {
      return null;
    }

    let data = {
      labels: itemData ? (itemData.keys || []) : [],
      datasets: [],
      ids: itemData ? (itemData.ids || []) : [],
    };

    Object.keys(itemConfig.options).forEach((optionKey) => {
      const graphOption = itemConfig.options[optionKey];
      if (!itemData[optionKey]) {
        return;
      }
      data.datasets.push({
        label: graphOption.title,
        data: itemData[optionKey],
        backgroundColor: graphOption.backgroundColor,
        borderColor: graphOption.borderColor,
        // line
        pointRadius: 1,
        pointBorderWidth: 1,
        pointHoverRadius: 7,
        pointHitRadius: 7,
        // custom
        columns: graphOption.columns,
        showIds: graphOption.showIds,
      });
    });

    let options = {
      title: {
        display: true,
        text: itemConfig.title,
      },
    }
    if (itemConfig.type !== 'pie') {
      options.scales = { yAxes: [ {
        ticks: {
          beginAtZero: true,
        },
        afterFit: function(scaleInstance) {
          scaleInstance.width += 10;
        }
      } ] };
    }

    const width = 1000;
    const height = 500;

    let chart = null;
    if (itemConfig.type === 'bar') {
      chart = <Bar data={data} options={options} width={width} height={height} />
    }
    if (itemConfig.type === 'pie') {
      chart = <Pie data={data} options={options} width={width} height={height} />
    }
    if (itemConfig.type === 'line') {
      chart = <Line data={data} options={options} width={width} height={height} />
    }
    if (itemConfig.type === 'list') {
      chart = <GraphListView data={data} options={options} objectPageKey={itemConfig.objectPageKey}/>
    }

    return (
      <div key={key} className='graph'>{chart}</div>
    )
  }

  render = () => {
    return (
      <div className='dashboardView'>

        <div className='listTitle'>
          {this.state.config.title}
          {this.renderSearch()}
        </div>

        {Object.keys(this.state.properties).map(this.renderGraph)}

      </div>
    )
  }
}

export default (withRouter(DashboardView));