import React from 'react';
import { withRouter } from 'react-router-dom';

import { sendRequest, triggerEvent, TIME_OPTIONS } from '../../helpers/global';

import SelectInput from '../input/SelectInput';
import DateInput from '../input/DateInput';

import '../../sass/components/BuildingTools/RepeatReviseView.scss';

class RepeatReviseView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: null,
      start_at: null,
      time_from: null,
      time_to: null,
      inspectors: [],
    }
  }

  componentDidMount = () => {
    this.requestInspectors();
  }

  requestInspectors = () => {
    sendRequest({
      method: 'common/inspectors',
      type: 'GET',
      success: (data) => {
        this.setState({ inspectors: (data || []) });
      },
      error: (data) => {
      }
    });
  }
  
  onConfirm = () => {
    const { locality_id, street_id, house, building } = this.props;
    const { user_id, start_at, time_from, time_to } = this.state;
    const data = { locality_id, user_id, start_at, time_from, time_to };
    if (street_id) {
      data.street_id = street_id;
    }
    if (house) {
      data.house = house;
    }
    if (building) {
      data.building = building;
    }
    sendRequest({
      method: 'tasks/duplicate_non_accessible',
      type: 'POST',
      data,
      success: (data) => {
        triggerEvent('showSnackbar', [{text: `Будет создано ${data?.tasks_count || 0} заданий`, type: 'success'}]);
        triggerEvent('closeContentPopup');
      },
      error: (data) => {
        triggerEvent('showSnackbar', [{text: 'Ошибка', type: 'error'}]);
      }
    });
  }

  render = () => {
    const { user_id, start_at, time_from, time_to } = this.state;
    const dataFilled = user_id && (!start_at || (time_from && time_to));
    const group_id = this.props.group_id;
    const filteredInspectors = this.state.inspectors.filter(i => i.group_id === group_id);
    return (
      <div className='repeatReviseView'>
        <div className='inputsContainer'>
          <SelectInput
            properties={{
              placeholder: 'Инспектор',
              options: filteredInspectors,
            }}
            object={user_id}
            onChange={(k, value) => this.setState({user_id: value})}
          />
          <DateInput
            properties={{
              type: 'date',
              clearable: true,
            }}
            placeholder='Дата проверки'
            object={start_at}
            onChange={(k, value) => this.setState({
              start_at: value,
              time_from: value ? time_from : null,
              time_to: value ? time_to : null,
            })}
          />
          {start_at ?
            <div className='dateRange'>
              <SelectInput
                properties={{
                  options: TIME_OPTIONS.slice(0,-1),
                  placeholder: 'Начало',
                }}
                object={time_from}
                onChange={(k, value) => this.setState({
                  time_from: value,
                  time_to: time_to <= value ? null : time_to,
                })}
              />
              <label> - </label>
              <SelectInput
                properties={{
                  options: TIME_OPTIONS.filter(i => i.id > time_from),
                  placeholder: 'Конец',
                }}
                object={time_to}
                onChange={(k, value) => this.setState({time_to: value})}
              />
            </div>
          : null}
        </div>
        <div className='buttonContainer'>
          <button
            disabled={!dataFilled}
            onClick={this.onConfirm}
          >Создать задания</button>
        </div>
      </div>
    )
  }
}

export default (withRouter(RepeatReviseView));
