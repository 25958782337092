import React from 'react';

import { sendRequest } from '../../helpers/global.js';

import '../../sass/components/input/FileUploadInput.scss';

export default class FileUploadInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.input = null;
  }

  uploadFile = (file) => {
    if (!file) {
      return;
    }
    this.props.onChange(this.props.objectKey, {
      ...this.props.object,
      uploading: file,
      uploaded: null,
      removed: false,
    });
    const formData = new FormData();
    formData.append('file', file);
    sendRequest({
      method: 'upload_files',
      type: 'POST',
      auth: true,
      formData,
      noLoad: true,
      success: (data) => {
        this.input.value = null;
        this.props.onChange(this.props.objectKey, {
          ...this.props.object,
          uploading: null,
          uploaded: data.file_url,
          removed: false,
        });
      },
      error: (data) => {
        this.input.value = null;
        this.props.onChange(this.props.objectKey, {
          ...this.props.object,
          uploading: null,
          uploaded: null,
        });
      }
    });
  }

  removeFile = () => {
    this.props.onChange(this.props.objectKey, {
      ...this.props.object,
      uploading: null,
      uploaded: null,
      removed: true,
    });
  }

  renderFile = () => {
    const object = this.props.object || {};
    if (typeof object === 'string') {
      const url = object;
      const name = url.split('/').pop().split('?')[0].split('#')[0];
      return (
        <div className='fileItem'>
          <span
            onClick={() => window.open(url, '_blank')}
            className='link'
          >{name}</span>
          <div className='removeButton' onClick={this.removeFile}/>
        </div>
      )
    } else if (object.deleted) {
      return null;
    } else if (object.uploading) {
      return (
        <div className='fileItem'>
          <span>{object.uploading.name}</span>
          <div className='uploadIndicator'/>
        </div>
      )
    } else if (object.uploaded) {
      const url = object.uploaded;
      const name = url.split('/').pop().split('?')[0].split('#')[0];
      return (
        <div className='fileItem'>
          <span
            onClick={() => window.open(url, '_blank')}
            className='link'
          >{name}</span>
          <div className='removeButton' onClick={this.removeFile}/>
        </div>
      )
    }
  }

  render = () => {
    const properties = this.props.properties;
    return (
      <div className='fileUploadInput'>
        {this.renderFile()}
        <input
          type='file'
          accept={properties.accept || '*'}
          ref={input => this.input = input}
          onChange={e => this.uploadFile(e.target.files[0])}
        />
        <div
          className='uploadButton'
          onClick={() => this.input.click()}
        >Choose file</div>
        {this.props.error ? 
          <div className='inputError'>{this.props.error}</div>
        : null}
      </div>
    )
  }

}
