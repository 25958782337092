import React from 'react';

import Storage from '../../helpers/Storage.js';
import { sendRequest, triggerEvent, validateEmail } from '../../helpers/global.js';

import TextInput from '../input/TextInput';

import '../../sass/components/SettingsView.scss';

class EditAccountView extends React.Component {
  constructor(props) {
    super(props);
    const user = Storage.getData('user') || {};
    this.state = {
      data: {
        email: user.email || '',
        name: user.name || '',
        phone: user.phone || '',
        password: '',
      },
      errors: {},
      formChanged: false,
    }
  }

  onSave = () => {
    const { email, name, phone, password } = this.state.data;
    let errors = {};
    if (!email) {
      errors.email = 'Email обязательный';
    } else if (!validateEmail(email)) {
      errors.email = 'Email невалидный';
    }
    this.setState({errors});
    if (Object.keys(errors).length > 0) {
      return;
    }
    sendRequest({
      method: 'me',
      type: 'PUT',
      data: {
        email,
        name,
        phone,
        password: password || null,
      },
      success: (data) => {
        Storage.setData('user', data);
        triggerEvent('showSnackbar', [{text: 'Учетная запись обновлена', type: 'success'}]);
      },
      error: (data) => {
        if (data?.errors) {
          this.setState({errors: data.errors});
        }
      }
    });
  }

  renderInputBlock = (key, title, type = 'text') => {
    return (
      <div className='inputBlock'>
        <label>{title}</label>
        <TextInput
          properties={{type}}
          object={this.state.data[key]}
          onChange={(k, val) =>
            this.setState({
              data: {
                ...this.state.data,
                [key]: val,
              },
              formChanged: true,
            })
          }
          error={this.state.errors[key]}
        />
      </div>
    )
  }

  render = () => {
    return (
      <div className='settingsBlock'>
        <div className='blockTitle'>Учетная запись</div>
        {this.renderInputBlock('email', 'Email')}
        {this.renderInputBlock('name', 'ФИО')}
        {this.renderInputBlock('phone', 'Телефон')}
        {this.renderInputBlock('password', 'Пароль', 'password')}
        <button
          onClick={this.onSave}
          disabled={!this.state.formChanged}
        >Сохранить</button>
      </div>
    )
  }
}

export default EditAccountView;
