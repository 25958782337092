export default {
  config: {
    method: "companies/",
    title: "Управляющие компании",
    objectName: "Управляющая компания",
    disableAdd: true,
    disableEdit: true,
    disableDelete: true,
    search: {
      name: {
        default: "",
        type: "text",
        title: "Название",
      },
      locality_id: {
        default: "",
        type: "select",
        title: "Н. пункт",
        request: "localities/all",
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "№",
      preset: true,
      width: "80px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Создан",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Обновлен",
      preset: true,
    },
    group_id: {
      default: "",
      type: "select",
      title: "Группа",
      request: "groups/all",
      width: "120px",
    },
    name: {
      default: "",
      type: "text",
      title: "Название",
      width: "auto",
    },
  }
};
