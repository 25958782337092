import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import SidebarSection from './SidebarSection.js';

import '../../sass/components/sidebar/SidebarView.scss';

const MENU_ITEMS = [
  {
    title: 'Администрирование',
    items: [
      { page: 'users' },
      { page: 'admin_tools' },
    ],
  },
  {
    title: 'Проверки',
    items: [
      { page: 'tasks' },
      { page: 'mass_edit_tool' },
      { page: 'photos' },
    ],
  },
  {
    title: 'Данные',
    defaultClose: true,
    items: [
      { page: 'areas' },
      { page: 'localities' },
      { page: 'streets' },
      { page: 'companies' },
    ],
  },
];

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class SidebarView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openedSections: [],
    };
  }

  onContainerClick = (e) => {
    if (e.target === e.currentTarget && this.props.onClose) {
      this.props.onClose()
    }
  }

  render = ()  => {
    return (
      <div
        className={classnames({
          'sidebarView': true,
          'customScrollBar': true,
          'open': this.props.open
        })}
        onClick={this.onContainerClick}
      >
        <div className='sidebarContainer'>
          <div className='menuList'>
            {MENU_ITEMS.map((section, index) =>
              <SidebarSection
                key={index}
                item={section}
                defaultClose={section.defaultClose}
                onSelect={page => {
                  if (this.props.onClose) {
                    this.props.onClose();
                  }
                  const path = `/${page}`;
                  if (path !== this.props.history.location.pathname) {
                    this.props.history.push(path);
                  }
                }}
              />
            )}
          </div>
          <div className='bottomControls'>
            <button
              className='outline'
              onClick={this.props.onLogout}
            >Выход</button>
          </div>
        </div>
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(SidebarView));
