export default {
  config: {
    method: "users/",
    title: "Пользователи",
    objectName: "Пользователь",
    adminOnly: true,
    auditableEntity: "User",
    search: {
      name: {
        default: "",
        type: "text",
        title: "ФИО",
      },
      locality_id: {
        default: "",
        type: "select",
        title: "Н. пункт",
        request: "localities/all",
      },
      email: {
        default: "",
        type: "text",
        title: "Email",
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "№",
      preset: true,
      width: "80px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Создан",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Обновлен",
      preset: true,
    },
    name: {
      default: "",
      type: "text",
      title: "ФИО",
      width: "auto",
    },
    group_id: {
      default: "",
      type: "select",
      title: "Группа",
      request: "groups/all",
      width: "120px",
    },
    email: {
      default: "",
      type: "text",
      title: "Email",
      width: "auto",
    },
    phone: {
      default: "",
      type: "text",
      title: "Телефон",
    },
    password: {
      type: "password",
      title: "Пароль",
    },
    role: {
      default: "",
      type: "select",
      title: "Роль",
      options: [
        { id: "inspector", name: "Инспектор" },
        { id: "manager", name: "Менеджер" },
        { id: "client_manager", name: "Менеджер заказчика" },
      ],
      width: "auto",
    },
    admin: {
      default: false,
      type: "checkbox",
      title: "Админ",
      tip: "Создает и редактирует пользователей",
      showCondition: object => object.role === "manager",
    },
    
  }
};
