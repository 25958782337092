export default {
  config: {
    method: "areas/",
    title: "Районы",
    objectName: "Район",
    disableAdd: true,
    disableEdit: true,
    disableDelete: true,
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "№",
      preset: true,
      width: "80px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Создан",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Обновлен",
      preset: true,
    },
    group_id: {
      default: "",
      type: "select",
      title: "Группа",
      request: "groups/all",
      width: "120px",
    },
    name: {
      default: "",
      type: "text",
      title: "Название",
      width: "auto",
    },
  }
};
