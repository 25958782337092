import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { eventOn, eventOff, triggerEvent } from '../helpers/global';

import SidebarView from './sidebar/SidebarView.js';
import DropdownMenu from './common/DropdownMenu.js';

import '../sass/components/HeaderView.scss';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
    isMobile: store.setup.isMobile,
  }
};

class HeaderView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      historyDepth: 1,
      title: null,
    };
  }

  componentDidMount = () => {
    this.props.history.listen((loc, type) => {
      if (type === 'PUSH') {
        this.setState({historyDepth: this.state.historyDepth + 1, title: null});
      } else if (type === 'POP') {
        this.setState({historyDepth: this.state.historyDepth - 1, title: null});
      } else {
        this.setState({title: null});
      }
    });
    eventOn('setHeaderTitle', this.setHeaderTitle);
  }

  componentWillUnmount = () => {
    eventOff('setHeaderTitle', this.setHeaderTitle);
  }

  setHeaderTitle = (e) => {
    const { title } = e.detail[0];
    this.setState({title});
  }

  getPageTilte = () => {
    return 'Мобильный контролер';
  }

  onLogout = () => {
    triggerEvent('showConfirmation', [{
      title: 'Выход из панели управления',
      confirmText: 'Выйти',
      cancelText: 'Отмена',
      callback: confirm => {
        if (confirm) {
          this.props.onLogout();
        }
      }
    }]);
  }

  render = () => {
    const user = this.props.user;
    if (!user) {
      return null;
    }
    return (
      <div className='headerView'>
        {user.role === 'client_manager' ?
          <span className='searchButton' onClick={() => this.props.history.push('/photos')}>
            <span className='material-icons'>search</span>
          </span>
          :
          <span className='menuButton' onClick={() => this.setState({menuOpen: !this.state.menuOpen})}>
            <span className='material-icons'>menu</span>
          </span>
        }
        <div className='headerLogo'/>
        <div className='pageInfo'>
          <div className='pageTitle'>{this.getPageTilte()}</div>
        </div>
        {/*this.state.historyDepth > 1 ?
          <span className='backLink' onClick={() => this.props.history.goBack()}>
            <span className='material-icons'>keyboard_backspace</span>
          </span>
        : null*/}
        <div className='userButton'>
          <span>{(user.name || user.email).substr(0, 1)}</span>
          <DropdownMenu
            anchorRight
            options={[
              {
                title: 'Настройки',
                onClick: () => this.props.history.push('/settings'),
              },
              {
                title: 'Выйти',
                onClick: () => this.onLogout(),
              },
            ]}
          />
        </div>
        {user.role === 'client_manager' ? null :
          <SidebarView
            open={this.state.menuOpen}
            onClose={() => this.setState({menuOpen: false})}
            onLogout={this.onLogout}
          />
        }
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(HeaderView));
