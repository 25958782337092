import React from 'react';
import { withRouter } from 'react-router-dom';

import { sendRequest } from '../helpers/global.js';

import TextInput from './input/TextInput.js';

import '../sass/components/LoginView.scss';

class ForgotPasswordView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      instructionsSent: false,
      data: {
        email: '',
      },
      errors: {},
    };
  }

  handleSubmit = () => {
    let data = this.state.data;
    let errors = {};
    if (!data.email) {
      errors.email = 'Please enter Email';
    }
    if (Object.keys(errors).length > 0) {
      this.setState({errors});
      return;
    }
    sendRequest({
      method: 'auth/forgot_password',
      auth: true,
      type: 'POST',
      data: {
        email: data.email,
        app: 'admin',
      },
      success: (data) => {
        this.setState({instructionsSent: true});
      },
      error: (data) => {
        if (data && data.errors) {
          this.setState({errors: data.errors});
        }
      }
    });
  }

  renderInput = (key, title, type = 'text') => {
    return (
      <div key={key}>
        <label htmlFor={key}>{title}</label>
        <TextInput
          properties={{type}}
          objectKey={key}
          object={this.state.data[key] || ''}
          onChange={(k, val) => this.setState({data: {...this.state.data, [key]: val}})}
          onFocus={() => this.setState({errors: {...this.state.errors, [key]: null}})}
          error={this.state.errors[key]}
        />
      </div>
    )
  }

  renderForm = () => {
    return (
      <div className='loginForm'>
        <div className='logo'>FOR BUSINESS</div>
        <div className='loginTitle'>Reset your password</div>
        <div className='loginSubtitle'>Not to worry, we’ll email you instructions to reset your password.</div>
        {this.renderInput('email', 'Email', 'email')}
        <button
          onClick={this.handleSubmit}
        >Send reset link</button>
        <div className='bottomRow'>
          <span
            onClick={() => this.props.history.push('/login')}
          >Return to login</span>
        </div>
      </div>
    )
  }

  renderComplete = () => {
    return (
      <div className='loginForm'>
        <div className='logo'>FOR BUSINESS</div>
        <div className='loginTitle'>Reset password link sent</div>
        <div className='loginSubtitle'>Please check your email for instructions to reset your password.</div>
        <button
          onClick={() => this.props.history.push('/login')}
        >Return to login</button>
      </div>
    )
  }

  render = () => {
    return (
      <div className='loginView'>
        {this.state.instructionsSent ? this.renderComplete() : this.renderForm()}
      </div>
    );
  }
}

export default withRouter(ForgotPasswordView);
