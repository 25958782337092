import React from 'react';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';

import appConfig from '../../config/';
import { sendRequest, triggerEvent } from '../../helpers/global';

import ObjectEditInput from '../common/ObjectEditInput';

import '../../sass/components/BuildingTools/MassEditView.scss';

const TASK_PROPERTIES = [
  'user_id',
  'start_at',
  'time_from',
  'time_to',
  'fact_date',
  'counter_type_match',
  'fact_counter_type',
  'counter_number_match',
  'counter_manufacture_year_match',
  'counter_place_match',
  'fact_counter_place',
  'zoning_match',
  'fact_zoning',
  'counter_sealed',
  'counter_not_broken',
];

class MassEditView extends React.Component {
  constructor(props) {
    super(props);
    const pageConfig = appConfig['tasks'];
    const properties = {...pageConfig.properties};
    const config = {...pageConfig.config};

    this.state = {
      properties,
      config,
      object: {
        group_code: this.props.group_code,
      },
      errors: {},
    }
  }

  handleValueChange = (key, value) => {
    this.setState(update(this.state, {
      object: {
        [key]: {$set: value},
      },
      errors: {
        [key]: {$set: null},
      },
    }));
  }
  
  onConfirm = () => {
    const { locality_id, street_id, house, building } = this.props;
    const data = { locality_id };
    if (street_id) {
      data.street_id = street_id;
    }
    if (house) {
      data.house = house;
    }
    if (building) {
      data.building = building;
    }
    TASK_PROPERTIES.forEach(key => {
      const value = this.state.object[key];
      if (value !== null && value !== '') {
        data[key] = value;
      }
    })
    sendRequest({
      method: 'tasks',
      type: 'PUT',
      data,
      success: (data) => {
        triggerEvent('showSnackbar', [{text: 'Задания обновлены', type: 'success'}]);
        triggerEvent('closeContentPopup');
      },
      error: (data) => {
        triggerEvent('showSnackbar', [{text: 'Ошибка', type: 'error'}]);
      }
    });
  }

  renderInput = (key) => {
    return (
      <ObjectEditInput
        key={key}
        inputKey={key}
        object={this.state.object}
        onChange={this.handleValueChange}
        error={this.state.errors[key]}
        inputProperties={this.state.properties[key]}
        readOnly={this.state.config.disableEdit}
      />
    )
  }

  render = () => {
    return (
      <div className='massEditView'>
        {TASK_PROPERTIES.map(this.renderInput)}
        <div className='buttonContainer'>
          <button onClick={this.onConfirm}>Сохранить</button>
        </div>
      </div>
    )
  }
}

export default (withRouter(MassEditView));
