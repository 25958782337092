import React from 'react';
import { withRouter } from "react-router-dom";

import '../../sass/components/input/PageLink.scss';

class PageLink extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
  }

  render = () => {
    const properties = this.props.properties;
    const object = this.props.object;
    if (!object) {
      return (
        <div className='pageLink'>
          {properties.defaultLabel || ''}
        </div>
      )
    }
    const label = object[properties.field || 'name'];
    return (
      <div className='pageLink'>
        <span
          onClick={() => {
            if (properties.external) {
              if (object) {
                window.open(object.url || object, '_blank');
              }
            } else {
              if (object.page) {
                this.props.history.push(
                  `/${object.page}/${(object || this.props.parentObject).id}`
                );
              } else {
                this.props.history.push(
                  properties.page.replace(':id', (object || this.props.parentObject).id)
                );
              }
            }
          }}
        >{label ? label : object || ''}</span>
      </div>
    )
  }
}

export default withRouter(PageLink);
