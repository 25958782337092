import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import '../../sass/components/common/Pagination.scss';

class Pagination extends React.Component {
  constructor(props) {
    super();
    this.state = {
    };
  }

  render = () => {
    const { page, maxPage, pageWindow } = this.props;
    const firstEnd = Math.min(pageWindow, maxPage);
    const currentBegin = Math.max(page - pageWindow, 1);
    const currentEnd = Math.min(page + pageWindow, maxPage);
    const lastBegin = Math.max(maxPage - pageWindow, 1);

    const intervals = [];
    if (firstEnd < currentBegin) {
      intervals.push([1, firstEnd]);
      if (currentEnd < lastBegin) {
        intervals.push([currentBegin, currentEnd]);
        intervals.push([lastBegin, maxPage]);
      } else {
        intervals.push([currentBegin, maxPage]);
      }
    } else {
      if (currentEnd < lastBegin) {
        intervals.push([1, currentEnd]);
        intervals.push([lastBegin, maxPage]);
      } else {
        intervals.push([1, maxPage]);
      }
    }
    const pages = [];
    intervals.forEach((interval, index) => {
      if (index > 0) {
        pages.push('ellipsis');
      }
      for (let i = interval[0]; i <= interval[1]; i++) {
        pages.push(i);
      }
    });

    return (
      <div className='pagination'>
        <div
          className='pageButton prev'
          onClick={() => {
            if (page - 1 >= 1) {
              this.props.onPageChange(page - 1)
            }
          }}
        >пред.</div>
        {pages.map((p, i) => {
          if (p === 'ellipsis') {
            return <div key={`e${i}`} className='ellipsis'/>
          } else {
            return (
              <div
                key={p}
                className={classnames({
                  'pageButton': true,
                  'selected': p === page
                })}
                onClick={() => this.props.onPageChange(p)}
              >{p}</div>
            )
          }
        })}
        <div
          className='pageButton next'
          onClick={() => {
            if (page + 1 <= maxPage) {
              this.props.onPageChange(page + 1)
            }
          }}
        >след.</div>
      </div>
    )
  }
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  maxPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageWindow: PropTypes.number,
};

Pagination.defaultProps = {
  pageWindow: 2
};

export default Pagination;
