import React from 'react';
import { withRouter } from 'react-router-dom';

import { sendRequest } from '../helpers/global.js';

import TextInput from './input/TextInput.js';

import '../sass/components/LoginView.scss';

class ResetPasswordView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      passwordUpdated: false,
      data: {
        password: '',
      },
      errors: {},
    };
  }
  
  paramToken = () => {
    return this.props.match.params.token;
  }

  handleSubmit = () => {
    let data = this.state.data;
    let errors = {};
    if (!data.password) {
      errors.password = 'Please enter Password';
    }
    if (Object.keys(errors).length > 0) {
      this.setState({errors});
      return;
    }
    sendRequest({
      method: 'auth/reset_password',
      auth: true,
      type: 'POST',
      data: {
        password: data.password,
        app: 'admin',
        reset_password_token: this.paramToken(),
      },
      success: (data) => {
        this.setState({passwordUpdated: true});
      },
      error: (data) => {
        if (data && data.errors) {
          this.setState({errors: data.errors});
        }
      }
    });
  }

  renderInput = (key, title, type = 'text') => {
    return (
      <div key={key}>
        <label htmlFor={key}>{title}</label>
        <TextInput
          properties={{type}}
          objectKey={key}
          object={this.state.data[key] || ''}
          onChange={(k, val) => this.setState({data: {...this.state.data, [key]: val}})}
          onFocus={() => this.setState({errors: {...this.state.errors, [key]: null}})}
          error={this.state.errors[key]}
        />
      </div>
    )
  }

  renderForm = () => {
    return (
      <div className='loginForm'>
        <div className='logo'>FOR BUSINESS</div>
        <div className='loginTitle'>New password</div>
        <div className='loginSubtitle'>Create a new password that is at least 8 characters long</div>
        {this.renderInput('password', 'New password', 'password')}
        <button
          onClick={this.handleSubmit}
        >Submit</button>
      </div>
    )
  }

  renderComplete = () => {
    return (
      <div className='loginForm'>
        <div className='logo'>FOR BUSINESS</div>
        <div className='loginTitle'>Success!</div>
        <div className='loginSubtitle'>Your password has be changed. Please try logging in again.</div>
        <button
          onClick={() => this.props.history.push('/login')}
        >Return to login</button>
      </div>
    )
  }

  render = () => {
    return (
      <div className='loginView'>
        {this.state.passwordUpdated ? this.renderComplete() : this.renderForm()}
      </div>
    );
  }
}

export default withRouter(ResetPasswordView);
