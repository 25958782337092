import React from 'react';

import { sendRequest, triggerEvent, fileToImageData } from '../../helpers/global.js';

import '../../sass/components/input/ImageList.scss';

export default class ImageList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.fileInputRef = React.createRef();
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
    setTimeout(() => {
      this.props.onChange(this.props.properties.ids_field, value.map(i => i.id));  
    }, 50);
  }

  handleFiles = (files, images) => {
    const file = files.shift();
    if (!file) {
      this.uploadImages(images)
      triggerEvent('removeLoad');
      if (this.fileInputRef.current) {
        this.fileInputRef.current.value = null;
      }
      return;
    }
    fileToImageData(file, 1024, (data) => {
      this.handleFiles(files, [...images, data]);
    });
  }

  uploadImages = (photos) => {
    const formData = new FormData();
    Promise.all(
      photos.map(i => new Promise((resolve, reject) => {
        try {
          fetch(i)
            .then(res => res.blob())
            .then(blob => {
              const prefix = this.props.parentObject[this.props.properties.prefixKey] || '';
              const datePart = (Date.now())%10e6;
              const randPart = (Math.random()*Number.MAX_VALUE)%10e6;
              const file = new File([blob], `${prefix}_${datePart}${randPart}.png`, { type: 'image/png'});
              formData.append('files[]', file);
              resolve();
            })
        } catch (error) {
          reject(error);
        }
      }))
    ).then(() => {
      sendRequest({
        method: this.props.properties.uploadRequest,
        type: 'POST',
        data: formData,
        success: (data) => {
          const object = this.props.object || [];
          this.handleChange([...object, ...data]);
        },
        error: (data) => {
        }
      });
    });
  }

  render = () => {
    const object = this.props.object || [];
    return (
      <div className='imageList'>
        <div className='imagesContainer'>
          {object.map((i) =>
            <div
              key={i.id}
              className='imageItem'
              style={{ backgroundImage: `url(${i.url})` }}
              onClick={() => window.open(i.url, '_blank')}
            >
              <div
                className='removePhoto'
                onClick={(e) => {
                  e.stopPropagation();
                  this.handleChange(object.filter(j => j.id !== i.id));
                }}
              >
                <span className='material-icons'>close</span>
              </div>
            </div>
          )}
        </div>
        <input
          type='file'
          multiple
          accept='image/*;capture=camera'
          ref={this.fileInputRef}
          onChange={e => {
            triggerEvent('addLoad');
            this.handleFiles([...e.target.files], []);
          }}
        />
        <div className='addPhotoButton'>
          <button
            className='outline acive'
            onClick={() => this.fileInputRef.current?.click()}
          >Выбрать фото</button>
        </div>
      </div>
    )
  }

}
