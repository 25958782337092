import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import Datetime from 'react-datetime';

import 'moment/locale/ru';

import '../../sass/components/input/DateInput.scss';

export default class DateInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (e) => {
    const properties = this.props.properties;
    if (e) {
      if (typeof e !== 'string') {
        if (properties.type === 'datetime') {
          this.props.onChange(this.props.objectKey, e.unix());
        } else {
          this.props.onChange(this.props.objectKey, e.format('YYYY-MM-DD'));
        }
      }
    } else {
      this.props.onChange(this.props.objectKey, null);
    }
  }

  render = () => {
    const properties = this.props.properties || {};
    const object = this.props.object;
    const value = object
      ? properties.type === 'datetime' ? object * 1000 : moment(object)
      : null;
    return (
      <div
        className={classnames({
          'dateInput': true,
          'simplified': properties.simplified,
          'expandTop': properties.expandTop,
          'clearable': properties.clearable,
        })}
      >
        <Datetime
          value={value}
          onChange={this.handleChange}
          inputProps={{
            disabled: this.props.disabled,
            placeholder: this.props.placeholder || properties.placeholder,
          }}
          dateFormat={properties.type === 'month' ? 'MMM YYYY' : 'DD.MM.YYYY'}
          timeFormat={properties.type === 'datetime' ? 'HH:mm' : false}
          closeOnSelect={true}
        />
        {properties.clearable ?
          <div
            className='clearButton'
            onClick={() => this.props.onChange(this.props.objectKey, null)}
          >
            <span className='material-icons'>close</span>
          </div>
        : null}
      </div>
    )
  }
}
