import React from 'react';

import { sendRequest } from '../../helpers/global';

import SelectInput from './SelectInput';

import '../../sass/components/input/DetailedSelectInput.scss';

class DetailedSelectInput extends React.Component {
  constructor(props) {
    super();
    this.state = {
      details: null,
    };
  }

  componentDidMount = () => {
    this.requestDetails();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.object !== prevProps.object) {
      this.requestDetails();
    }
  }

  requestDetails = () => {
    const properties = this.props.properties;
    if (this.props.object) {
      const method = properties.detailsMethod.replace(':id', this.props.object);
      sendRequest({
        method,
        type: 'GET',
        success: (data) => {
          this.setState({details: data});
        },
        error: (data) => {
        }
      });
    } else {
      this.setState({details: null});
    }
  }

  renderDetails = () => {
    const details = this.state.details;
    if (!details) {
      return null;
    }
    const properties = this.props.properties;
    return (
      <table className='detailsTable'>
        <thead>
          <tr>
            {properties.detailsFields.map((field, index) =>
              <th key={index}>{field.title}</th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            {properties.detailsFields.map((field, index) =>
              <td key={index}>{details[field.key] || ''}</td>
            )}
          </tr>
        </tbody>
      </table>
    )
  }

  render = () => {
    return (
      <div className='detailedSelectInput'>
        <SelectInput
          {...this.props}
          properties={{
            ...this.props.properties,
            type: 'select',
          }}
        />
        {this.renderDetails()}
      </div>
    )
  }
}

export default DetailedSelectInput;
