import React from 'react';
import { withRouter } from 'react-router-dom';

import { sendRequest } from '../../helpers/global.js';

import '../../sass/components/input/AdditionalButtons.scss';

class AdditionalButtons extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  renderVoucherButtons = () => {
    const onDuplicate = () => {
      sendRequest({
        method: `vouchers/${this.props.parentObject.id}/duplicate`,
        type: 'POST',
        success: (data) => {
          this.props.history.push(`/vouchers/${data.id}`);
        },
        error: (data) => {
        }
      });
    }
    return (
      <div className='additionalButtons'>
        <button className='outline' onClick={onDuplicate}>Duplicate voucher</button>
      </div>
    )
  }

  render = () => {
    switch (this.props.properties.subtype) {
      case 'voucher-buttons':
        return this.renderVoucherButtons();
      default:
        return null;
    } 
  }

}

export default withRouter(AdditionalButtons)
