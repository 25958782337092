import React from 'react';

import { sendRequest } from '../../helpers/global.js';

import '../../sass/components/input/FileUploadInput.scss';

export default class FileMultiUploadInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.input = null;
  }

  uploadFile = (file) => {
    if (!file) {
      return;
    }
    const id = `$${Date.now()}`;
    this.props.onChange(this.props.objectKey, [
      ...this.props.object, {
        id,
        uploading: file,
        uploaded: null,
      }
    ]);
    const formData = new FormData();
    formData.append('file', file);
    sendRequest({
      method: 'upload_files',
      type: 'POST',
      auth: true,
      formData,
      noLoad: true,
      success: (data) => {
        this.props.onChange(this.props.objectKey, this.props.object.map(
          i => i.id === id ? {
            ...i,
            uploading: null,
            uploaded: data.file_url,
          } : i
        ));
      },
      error: (data) => {
        this.removeFile(id);
      }
    });
    this.input.value = null;
  }

  removeFile = (id) => {
    this.props.onChange(this.props.objectKey, this.props.object.filter(
      i => i.id !== id
    ));
  }

  renderFile = (item) => {
    if (item.uploading) {
      return (
        <div key={item.id} className='fileItem'>
          <span>{item.uploading.name}</span>
          <div className='uploadIndicator'/>
        </div>
      )
    } else if (item.uploaded || item.image_url) {
      const url = item.uploaded || item.image_url;
      const name = url.split('/').pop().split('?')[0].split('#')[0];
      return (
        <div key={item.id} className='fileItem'>
          <span
            onClick={() => window.open(url, '_blank')}
            className='link'
          >{name}</span>
          <div className='removeButton' onClick={() => this.removeFile(item.id)}/>
        </div>
      )
    }
  }

  render = () => {
    const properties = this.props.properties;
    const object = this.props.object || [];
    return (
      <div className='fileUploadInput'>
        {object.map(this.renderFile)}
        <input
          type='file'
          accept={properties.accept || '*'}
          ref={input => this.input = input}
          onChange={e => this.uploadFile(e.target.files[0])}
        />
        <div
          className='uploadButton'
          onClick={() => this.input.click()}
        >Choose file</div>
        {this.props.error ? 
          <div className='inputError'>{this.props.error}</div>
        : null}
      </div>
    )
  }

}
