import React from 'react';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import { saveAs } from 'file-saver';

import appConfig from '../config/';
import { sendRequest, triggerEvent } from '../helpers/global.js';

import TabsView from './common/TabsView';
import ObjectEditInput from './common/ObjectEditInput';
import { stateToHtml } from './input/WysiwygInput';

import '../sass/components/ObjectEditView.scss';

class ObjectEditView extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  paramId = () => {
    const id = this.props.match.params.id;
    return id === 'create' ? '' : id;
  }

  getDefaultState = (props) => {
    const pageConfig = appConfig[props.configKey];
    const properties = {...pageConfig.properties};
    const config = {...pageConfig.config};
    let object = {};
    Object.keys(properties).forEach(key => {
      if (key === 'id') {
        object[key] = isNaN(this.paramId()) ? this.paramId() : Number(this.paramId());
      } else if (props.object && props.object[key]) {
        object[key] = props.object[key];
      } else {
        if (properties[key].default === null) {
          object[key] = properties[key].default;
        } else if (Array.isArray(properties[key].default)) {
          object[key] = properties[key].default.slice();
        } else if (typeof properties[key].default === 'object') {
          object[key] = Object.assign({}, properties[key].default);
        } else {
          object[key] = properties[key].default;
        }
      }
    });
    let state = {
      object: object,
      properties: properties,
      config: config,
      errors: {},
      dataReceived: false,
      tab: 0,
    }
    return state;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.configKey) {
      if (this.props.location !== prevProps.location) {
        this.setState(this.getDefaultState(this.props), () => {
          this.requestInitialData();
        });
      }
    }
  }

  updateStateWithData = (data) => {
    const properties = this.state.properties;
    let object = {...this.state.object, ...data};
    Object.keys(properties).forEach(key => {
      if (object[key] === undefined || object[key] === null) {
        object[key] = properties[key].default || null;
      }
    });
    this.setState({ object, dataReceived: true });
  }

  componentDidMount = () => {
    this.requestInitialData();
  }

  requestInitialData = () => {
    if (!this.paramId()) {
      this.setState({dataReceived: true});
      return;
    }
    sendRequest({
      method: this.state.config.method + this.paramId(),
      type: 'GET',
      success: (data) => {
        this.updateStateWithData(data);
      },
      error: (data) => {
      }
    });
  }

  onCancel = () => {
    this.props.history.goBack();
  }

  handleSubmit = () => {
    let data = {};
    Object.keys(this.state.object).forEach(key => {
      const value = this.state.object[key];
      const properties = this.state.properties[key];
      if (!properties) {
      } else if (value === null || value === undefined) {
        if (properties.type === 'select' && properties.type === 'multi-select') {
          data[key] = null;
        }
      } else if (['image', 'file'].indexOf(properties.type) > -1) {
        if (typeof value !== 'string') {
          data[key] = value;
        }
      } else if (properties.type === 'wysiwyg') {
        data[key] = stateToHtml(value);
      } else if (properties.type === 'file-upload') {
        if (typeof value === 'object') {
          if (value.removed) {
            data[key] = null;
          } else if (value.uploaded) {
            data[key] = value.uploaded;
          }
        }
      } else {
        data[key] = value;
      }
    });
    sendRequest({
      method: this.state.config.method + this.paramId(),
      type: this.paramId() ? 'PUT' : 'POST',
      data,
      success: (data) => {
        if (data.id) {
          this.updateStateWithData(data);
          //this.props.history.goBack();
          triggerEvent('showSnackbar', [{text: `Запись ${this.state.config.objectName} обновлена`}]);
        } else if (data.errors) {
          this.setState({errors: data.errors}, this.scrollIntoError);
        }
      },
      error: (data) => {
        if (data.errors) {
          this.setState({errors: data.errors}, this.scrollIntoError);
        }
      }
    });
  }

  handleValueChange = (key, value) => {
    this.setState(update(this.state, {
      object: {
        [key]: {$set: value},
      },
      errors: {
        [key]: {$set: null},
      },
    }));
  }

  scrollIntoError = () => {
    const keys = Object.keys(this.state.errors);
    if (keys[0]) {
      const elem = document.querySelector(`#input-${keys[0]}`);
      if (elem) {
        elem.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }
  }

  showAudit = () => {
    triggerEvent('showAudit', [{
      config: this.state.config,
      properties: this.state.properties,
      id: this.state.object.id
    }]);
  }

  onExportItem = () => {
    sendRequest({
      method: this.state.config.exportItem?.method?.replace(':id', this.state.object.id),
      type: 'GET',
      responseType: 'blob',
      success: (data, response) => {
        var fileData = new Blob([data], {
          type: response.headers['content-type'],
        });
        const match = decodeURI(response.headers['content-disposition'])?.match(/filename=(.*)/);
        saveAs(fileData, match?.[1] || Date.now());
      },
      error: (data) => {
      }
    });
  }

  renderInput = (key, extendProperties) => {
    const inputProperties = {...this.state.properties[key], ...extendProperties};
    return (
      <ObjectEditInput
        key={key}
        inputKey={key}
        object={this.state.object}
        onChange={this.handleValueChange}
        error={this.state.errors[key]}
        inputProperties={inputProperties}
        readOnly={this.state.config.disableEdit}
      />
    )
  }

  render = () => {
    const { sections } = this.state.config;

    if (!this.state.dataReceived) {
      return null;
    }

    const { modifyCondition, disableEdit, createOnly, bottomControls = [], editView } = this.state.config;
    let showActionButton = true;
    if (disableEdit || this.state.object.deleted || (createOnly && this.state.object?.id)) {
      showActionButton = false;
    }
    if (modifyCondition && !modifyCondition(this.state.object)) {
      showActionButton = false;
    }

    return (
      <div className='objectEditView'>

      <div className='pageTitle'>
        <span className='backLink' onClick={this.onCancel}>
          <span className='material-icons'>keyboard_backspace</span>
        </span>
        {this.state.config.objectName}
        {this.state.config.auditableEntity && this.state.object?.id ?
          <div className='auditButton' onClick={this.showAudit}>
            <div className='tooltip left'>История изменений</div>
            <span className='material-icons'>history</span>
          </div>
        : null}
        {this.state.config.exportItem ?
          <button
            className='primary'
            onClick={this.onExportItem}
          >{this.state.config.exportItem.title}</button>
        : null}
      </div>
      
      {editView ? <>
        {React.createElement(editView, {
          item: this.state.object,
          errors: this.state.errors,
          onChange: this.handleValueChange,
          onError: errors => this.setState({errors}, this.scrollIntoError),
          renderInput: this.renderInput,
        })}
        {showActionButton ?
          <div className='buttonContainer left'>
            <button onClick={this.onCancel} className='outline'>Назад</button>
            <button onClick={this.handleSubmit}>Сохранить</button>
          </div>
        : null}
      </> :
        <div className='card'>
          {sections
            ? <>
                <TabsView
                  compact
                  tabs={sections.map((section, index) => ({
                    key: index,
                    title: section.title,
                  }))}
                  tab={this.state.tab}
                  onTabChange={index => this.setState({tab: index})}
                />
                {sections[this.state.tab]?.fields?.map(this.renderInput)}
              </>
            : <div className='inputsBlock'>
              {Object.keys(this.state.properties).map(this.renderInput)}
            </div>
          }
          {showActionButton ?
            <div className='buttonContainer'>
              <button onClick={this.onCancel} className='outline'>Назад</button>
              <button onClick={this.handleSubmit}>Сохранить</button>
            </div>
          : null}
        </div>
      }

        {bottomControls.map((control, i) =>
          React.createElement(control, {
            key: i,
            item: this.state.object,
            onChange: object => this.setState({object}),
          })
        )}

      </div>
    );
  }
}

export default withRouter(ObjectEditView);
