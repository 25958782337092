import React from 'react';
import { withRouter } from 'react-router-dom';

import EditAccountView from './common/EditAccountView';

import '../sass/components/SettingsView.scss';

class SettingsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render = () => {
    return (
      <div className='settingsView'>
        <div className='settingsTitle'>Настройки</div>
        <EditAccountView/>
      </div>
    )
  }
}

export default (withRouter(SettingsView));
