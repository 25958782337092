import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import appConfig from '../../config/';

import '../../sass/components/sidebar/SidebarSection.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class SidebarSection extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: props.defaultClose ? false : true,
    };
  }

  renderMenuItem = (page) => {
    const pageConfig = appConfig[page];
    if (!pageConfig) {
      return null;
    }
    if (pageConfig.config.adminOnly && !this.props.user.admin) {
      return null;
    }
    return (
      <div
        key={page}
        className='menuItem'
        onClick={() => {
          if (this.props.onSelect) {
            this.props.onSelect(page);
          }
        }}
      >
        {pageConfig.config.title}
      </div>
    )
  }

  render = ()  => {
    const item = this.props.item;
    if (!item) {
      return null;
    }
    if (item.page) {
      return this.renderMenuItem(item.page);
    }
    const level = this.props.level || 0;
    return (
      <div
        className={classnames({
          'sidebarSection': true,
          'open': this.state.open,
          [`level-${level}`]: true,
        })}
      >
        <div
          className='sectionHeader'
          onClick={e => {
            if (e.target === e.currentTarget) {
              this.setState({open: !this.state.open})
            }
          }}
        >{item.title}</div>
        <div className='sectionItems'>
          {item.items.map((child, index) =>
            <SidebarSection key={index} {...this.props} item={child} level={level+1}/>
          )}
        </div>
      </div>
    )
  }

}

export default connect(mapStoreToProps)(SidebarSection);
