import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { saveAs } from 'file-saver';

import { sendRequest } from '../../helpers/global';

import SelectInput from '../input/SelectInput';
import DateInput from '../input/DateInput';

import '../../sass/components/AdminToolsView.scss';

class PrintecsExportTasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inspector_id: null,
      department_id: null,
      from: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      departments: [],
      inspectors: [],
    }
  }

  componentDidMount = () => {
    this.requestDepartments();
    this.requestInspectors();
  }

  requestDepartments = () => {
    sendRequest({
      method: 'departments/all',
      type: 'GET',
      success: (data) => {
        this.setState({ departments: (data || []) });
      },
      error: (data) => {
      }
    });
  }

  requestInspectors = () => {
    sendRequest({
      method: 'common/inspectors',
      type: 'GET',
      success: (data) => {
        this.setState({ inspectors: (data || []) });
      },
      error: (data) => {
      }
    });
  }

  requestUserReport = () => {
    const { group_id } = this.props;
    const { inspector_id, department_id, from, to } = this.state;
    sendRequest({
      method: 'export/report',
      type: 'GET',
      responseType: 'blob',
      data: {
        group_id,
        inspector_id,
        department_id,
        from,
        to
      },
      success: (data, response) => {
        var fileData = new Blob([data], {
          type: response.headers['content-type'],
        });
        const match = decodeURI(response.headers['content-disposition'])?.match(/filename=(.*)/);
        saveAs(fileData, match?.[1] || Date.now());
      },
      error: (data) => {
      }
    });
  }

  render = () => {
    const { group_id } = this.props;
    const { inspector_id, department_id, from, to } = this.state;
    const filteredDepartments = this.state.departments.filter(d => d.group_id === group_id);
    const filteredInspectors = this.state.inspectors.filter(i => i.group_id === group_id);
    return (
      <div className='toolSection'>
        <div className='toolName'>Отчет по датам</div>
        <SelectInput
          properties={{
            clearable: true,
            placeholder: 'Инспектор',
            options: filteredInspectors,
          }}
          object={inspector_id}
          onChange={(k, value) => this.setState({inspector_id: value})}
        />
        <SelectInput
          properties={{
            clearable: true,
            placeholder: 'Отделение',
            options: filteredDepartments,
          }}
          object={department_id}
          onChange={(k, value) => this.setState({department_id: value})}
        />
        <div className='dateRange'>
          <label>Даты проверок с</label>
          <DateInput
            properties={{
              type: 'date'
            }}
            object={from}
            onChange={(k, value) => this.setState({from: value})}
          />
          <label>до</label>
          <DateInput
            properties={{
              type: 'date'
            }}
            object={to}
            onChange={(k, value) => this.setState({to: value})}
          />
        </div>
        <button
          disabled={!from || !to}
          onClick={this.requestUserReport}
        >Сформировать отчет</button>
      </div>
    )
  }
}

export default (withRouter(PrintecsExportTasks));
