import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

import '../../sass/components/input/TextInput.scss';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.isMobile,
  }
};

class TextInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
    this.input = null;
  }

  handleChange = (e) => {
    let value = e.target.value;
    if (this.props.properties.type === 'number') {
      value = value.replace(/[^\d.]/g, '');
    }
    if (this.props.onChange) {
      this.props.onChange(this.props.objectKey, value);
    }
  }

  componentDidMount = () => {
  }

  renderPasswordToggle = () => {
    return (
      <div
        className={classnames('passwordToggle', {
          'visible': this.state.showPassword
        })}
        onClick={() => this.setState({showPassword: !this.state.showPassword})}
      />
    )
  }

  renderTextInput = () => {
    const properties = this.props.properties;
    let value = this.props.object;
    if ([null, undefined].indexOf(value) > -1 && this.props.onChange) {
      value = '';
    }
    let type = properties.type;
    if (type === 'password' && this.state.showPassword) {
      type = 'text';
    }
    if (type === 'number' && !this.props.isMobile) {
      type = 'text';
    }
    return (
      <input
        type={type}
        value={value}
        onChange={this.handleChange}
        readOnly={this.props.disabled}
        className="form-control"
        placeholder={this.props.placeholder || properties.placeholder}
        autoComplete={this.props.objectKey}
        onFocus={() => {
          if (this.props.onFocus) this.props.onFocus();
        }}
        onBlur={() => {
          if (this.props.onBlur) this.props.onBlur();
        }}
        ref={input => {
          this.input = input;
          if (this.props.inputRef) {
            this.props.inputRef(this.input);
          }
        }}
      />
    )
  }

  renderTextareaInput = () => {
    const properties = this.props.properties;
    let value = this.props.object;
    if ([null, undefined].indexOf(value) > -1 && this.props.onChange) {
      value = '';
    }
    return (
      <textarea 
        value={value}
        onChange={this.handleChange}
        readOnly={this.props.disabled}
        className="form-control"
        placeholder={this.props.placeholder || properties.placeholder}
        onFocus={() => {
          if (this.props.onFocus) this.props.onFocus();
        }}
        onBlur={() => {
          if (this.props.onBlur) this.props.onBlur();
        }}
        ref={input => {
          this.input = input;
          if (this.props.inputRef) {
            this.props.inputRef(this.input);
          }
        }}
      />
    )
  }

  render = () => {
    const properties = this.props.properties;
    return (
      <div
        className={classnames({
          'textInput': true,
          'simplified': properties.simplified,
          [properties.type]: true,
          'error': this.props.error,
        })}
      >
        {['text', 'number', 'password', 'email'].indexOf(properties.type) > -1 ? this.renderTextInput() : null}
        {properties.type === 'textarea' ? this.renderTextareaInput() : null}
        {properties.type === 'password' ? this.renderPasswordToggle() : null}
        {this.props.error && this.props.error.length ? 
          <div className='inputError'>{this.props.error}</div>
        : null}
      </div>
    )
  }
}

export default connect(mapStoreToProps)(TextInput);
