import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import SelectInput from '../input/SelectInput';
import MassEditView from './MassEditView';
import RepeatReviseView from './RepeatReviseView';

import { sendRequest, triggerEvent, compareStringNums } from '../../helpers/global';

import '../../sass/components/BuildingTools/MassEditTool.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class MassEditTool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group_id: !props.user?.group?.admin ? props.user?.group?.id : null,
      area_id: null,
      locality_id: null,
      street_id: null,
      house_building: null,
      groups: [],
      areas: [],
      localities: [],
      streets: [],
      houseBuildings: [],
    }
  }

  componentDidMount = () => {
    this.requestGroups();
    this.requestAreas();
  }

  requestGroups = () => {
    sendRequest({
      method: 'groups/all',
      type: 'GET',
      success: (data) => {
        this.setState({ groups: (data || []).filter(g => !g.admin) });
      },
      error: (data) => {
      }
    });
  }

  requestAreas = () => {
    sendRequest({
      method: 'areas/all',
      type: 'GET',
      success: (data) => {
        this.setState({areas: data});
      },
      error: (data) => {
      }
    });
  }

  requestLocalities = () => {
    sendRequest({
      method: 'localities/for_area',
      type: 'GET',
      data: {
        area_id: this.state.area_id,
      },
      success: (data) => {
        this.setState({localities: data});
      },
      error: (data) => {
      }
    });
  }

  requestStreets = () => {
    sendRequest({
      method: 'streets/for_locality',
      type: 'GET',
      data: {
        locality_id: this.state.locality_id,
      },
      success: (data) => {
        this.setState({streets: data});
      },
      error: (data) => {
      }
    });
  }

  requestHouseBuildings = () => {
    sendRequest({
      method: `streets/${this.state.street_id}/houses_and_buildings`,
      type: 'GET',
      success: (data) => {
        const houseBuildings = data.map(i => {
          const parts = i.split('::');
          return {
            id: i,
            house: parts[0],
            building: parts[1] || '',
            name: parts[0] + (parts[1] ? ` корпус ${parts[1]}` : ''),
          }
        }).sort((a, b) => {
          if (a.house !== b.house) {
            return compareStringNums(a.house, b.house);
          }
          return compareStringNums(a.building, b.building);
        });
        this.setState({houseBuildings});
      },
      error: (data) => {
      }
    });
  }
  
  onDeleteTasks = () => {
    const { locality_id, street_id, house_building, localities, streets } = this.state;
    const locality = localities.find(i => i.id === locality_id)?.name;
    const addressParts = [ locality ];
    if (street_id) {
      const street = streets.find(i => i.id === street_id)?.name;
      addressParts.push(street);
    }
    if (house_building) {
      const parts = house_building.split('::');
      const house = parts[0];
      const building = parts[1] || null;
      addressParts.push(house);
      addressParts.push(building);
    }
    const address = addressParts.filter(i => !!i).join(', ');
    triggerEvent('showConfirmation', [{
      title: `${address}`,
      body: `Удалить все задания по адресу?`,
      confirmText: 'Удалить',
      cancelText: 'Отмена',
      danger: true,
      callback: confirm => {
        if (confirm) {
          this.deleteTasks();
        }
      }
    }]);
  }

  deleteTasks = () => {
    const { locality_id, street_id, house_building } = this.state;
    const data = { locality_id };
    if (street_id) {
      data.street_id = street_id;
    }
    if (house_building) {
      const parts = house_building.split('::');
      const house = parts[0];
      const building = parts[1] || null;
      if (house) {
        data.house = house;
      }
      if (building) {
        data.building = building;
      }
    }
    sendRequest({
      method: 'tasks/mass_deletion',
      type: 'POST',
      data,
      success: (data, response) => {
        triggerEvent('showSnackbar', [{text: 'Задания по адресу удалены'}]);
      },
      error: (data) => {
      }
    });
  }

  onViewTasks = () => {
    const { group_id, area_id, locality_id, street_id, house_building } = this.state;
    let url = `/tasks?group_id=${group_id}&area_id=${area_id}&locality_id=${locality_id}&outdated=false`;
    if (street_id) {
      url += `&street_id=${street_id}`;
    }
    if (house_building) {
      const parts = house_building.split('::');
      const house = parts[0];
      const building = parts[1] || null;
      if (house) {
        url += `&house=${house}`;
      }
      if (building) {
        url += `&building=${building}`;
      }
    }
    window.open(url, '_blank');
  }

  onEditTasks = () => {
    const { group_id, locality_id, street_id, house_building, localities, streets } = this.state;
    const locality = localities.find(i => i.id === locality_id)?.name;
    const street = streets.find(i => i.id === street_id)?.name;
    let house = null;
    let building = null;
    if (house_building) {
      const parts = house_building.split('::');
      house = parts[0];
      building = parts[1] || null;
    }
    const group = this.state.groups.find(i => i.id === group_id);
    const address = [ locality, street, house, building ].filter(i => !!i).join(', ');
    triggerEvent('showContentPopup', [{
      title: <>Массовое редактирование заданий<br/>{address}</>,
      content: <MassEditView
        group_code={group?.code}
        locality_id={locality_id}
        street_id={street_id}
        house={house}
        building={building}
        key={Date.now()}
      />,
      buttonText: false,
    }]);
  }

  onRepeatRevise = () => {
    const { group_id, locality_id, street_id, house_building, localities, streets } = this.state;
    const locality = localities.find(i => i.id === locality_id)?.name;
    const street = streets.find(i => i.id === street_id)?.name;
    let house = null;
    let building = null;
    if (house_building) {
      const parts = house_building.split('::');
      house = parts[0];
      building = parts[1] || null;
    }
    const address = [ locality, street, house, building ].filter(i => !!i).join(', ');
    triggerEvent('showContentPopup', [{
      title: <>Вторичное снятие<br/>{address}</>,
      content: <RepeatReviseView
        group_id={group_id}
        locality_id={locality_id}
        street_id={street_id}
        house={house}
        building={building}
      />,
      buttonText: false,
    }]);
  }

  render = () => {
    const {
      group_id, area_id, locality_id, street_id, house_building,
      groups, areas, localities, streets, houseBuildings,
    } = this.state;
    const filteredAreas = areas.filter(i => i.group_id === group_id);
    return (
      <div className='massEditTool'>
        <div className='searchTitle'>
          <label>Массовые действия</label>
        </div>
        <div className='selectsContainer'>
          <SelectInput
            properties={{
              clearable: true,
              options: groups,
              placeholder: 'Группа',
            }}
            disabled={!this.props.user?.group?.admin}
            object={group_id}
            onChange={(k, value) => {
              this.setState(group_id !== value ? {
                group_id: value,
                area_id: null,
                locality_id: null,
                street_id: null,
                house_building: null,
                localities: [],
                streets: [],
                houseBuildings: [],
              } : {})
            }}
          />
          <SelectInput
            properties={{
              clearable: true,
              options: filteredAreas,
              placeholder: 'Район',
            }}
            object={area_id}
            onChange={(k, value) => {
              this.setState(area_id !== value ? {
                area_id: value,
                locality_id: null,
                street_id: null,
                house_building: null,
                localities: [],
                streets: [],
                houseBuildings: [],
              } : {}, this.requestLocalities)
            }}
          />
          <SelectInput
            properties={{
              clearable: true,
              options: localities,
              placeholder: 'Населенный пункт',
            }}
            object={locality_id}
            onChange={(k, value) => {
              this.setState(locality_id !== value ? {
                locality_id: value,
                street_id: null,
                house_building: null,
                streets: [],
                houseBuildings: [],
              } : {}, this.requestStreets)
            }}
          />
          <SelectInput
            properties={{
              clearable: true,
              options: streets,
              placeholder: 'Улица',
            }}
            object={street_id}
            onChange={(k, value) => {
              this.setState(street_id !== value ? {
                street_id: value,
                house_building: null,
                houseBuildings: [],
              } : {}, this.requestHouseBuildings)
            }}
          />
          <SelectInput
            properties={{
              clearable: true,
              options: houseBuildings,
              placeholder: 'Дом, корпус',
            }}
            object={house_building}
            onChange={(k, value) => {
              this.setState({house_building: value})
            }}
          />
        </div>
        <div className='buttonsContainer'>
          {!area_id || !locality_id ? 
            <div className='buttonPlaceholder'>Выберите населенный пункт (улица и дом - опционально)</div>
            : <>
              <button
                onClick={this.onEditTasks}
              >Массовое редактирование</button>
              <button
                onClick={this.onRepeatRevise}
              >Вторичное снятие</button>
              <button
                onClick={this.onViewTasks}
              >К списку заданий</button>
              <button
                className='danger'
                onClick={this.onDeleteTasks}
              >Удалить задания</button>
            </>
          }
        </div>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(MassEditTool));
