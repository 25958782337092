import React from 'react';

import ObjectListView from '../ObjectListView.js';
import SelectInput from '../input/SelectInput.js';
import CheckboxInput from '../input/CheckboxInput.js';
import SwitchInput from '../input/SwitchInput.js';
import DateInput from '../input/DateInput.js';
import WysiwygInput from '../input/WysiwygInput.js';
import TextInput from '../input/TextInput.js';
import JsonInput from '../input/JsonInput.js';
import FileInput from '../input/FileInput.js';
import FileUploadInput from '../input/FileUploadInput.js';
import FileMultiUploadInput from '../input/FileMultiUploadInput.js';
import LinkInput from '../input/LinkInput.js';
import PageLink from '../input/PageLink.js';
import ImageInput from '../input/ImageInput.js';
import TodoList from '../input/TodoList.js';
import LinksList from '../input/LinksList.js';
import DetailedSelectInput from '../input/DetailedSelectInput.js';
import AdditionalButtons from '../input/AdditionalButtons.js';
import ChangesInput from '../input/ChangesInput.js';
import ImageList from '../input/ImageList.js';

import UserAvatarView from '../UserAvatarView';

export default class FlexibleInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    var properties = this.props.properties;
    switch (properties.type) {
      case "object-list":
        const { properties, ...props } = this.props;
        return <ObjectListView
          embedded
          configKey={properties.configKey}
          config={properties.config}
          properties={properties.properties}
          {...props}
        />
      case "text":
      case "number":
      case "password":
      case "textarea":
        return <TextInput  properties={properties} {...this.props} />
      case "wysiwyg":
        return <WysiwygInput properties={properties} {...this.props} />
      case "select":
      case "multi-select":
        return <SelectInput properties={properties} {...this.props} />
      case "checkbox":
        return <CheckboxInput properties={properties} {...this.props} />
      case "switch":
        return <SwitchInput properties={properties} {...this.props} />
      case "date":
      case "datetime":
      case "month":
        return <DateInput properties={properties} {...this.props} />
      case "file":
        return <FileInput properties={properties} {...this.props} />
      case "file-upload":
        return <FileUploadInput properties={properties} {...this.props} />
      case "file-multi-upload":
        return <FileMultiUploadInput properties={properties} {...this.props} />
      case "link":
        return <LinkInput properties={properties} {...this.props} />
      case "json":
        return <JsonInput properties={properties} {...this.props} />
      case "avatar":
        return <UserAvatarView properties={properties} {...this.props} />
      case "page-link":
        return <PageLink properties={properties} {...this.props} />
      case "image":
        return <ImageInput properties={properties} {...this.props} />
      case "todo-list":
        return <TodoList properties={properties} {...this.props} />
      case "links-list":
        return <LinksList properties={properties} {...this.props} />
      case "detailed-select":
        return <DetailedSelectInput properties={properties} {...this.props} />
      case "additional-buttons":
        return <AdditionalButtons properties={properties} {...this.props} />
      case "changes":
        return <ChangesInput properties={properties} {...this.props} />
      case "image-list":
        return <ImageList properties={properties} {...this.props} />
      default:
        return null;
    }
  }
}
