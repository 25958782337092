import React from 'react';
import { withRouter } from 'react-router-dom';

import { sendRequest, triggerEvent } from '../../helpers/global';

import FileInput from '../input/FileInput';

import '../../sass/components/AdminToolsView.scss';

class ImportReadings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
    }
  }

  onImport = () => {
    const { group } = this.props;
    const { file } = this.state;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('group_id', group?.id);
    sendRequest({
      method: 'import/readings',
      type: 'POST',
      formData,
      success: (data) => {
        this.setState({file: null})
        triggerEvent('showSnackbar', [{text: 'Записи в обработке', type: 'success'}]);
      },
      error: (data) => {
      }
    });
  }

  render = () => {
    const { group } = this.props;
    const { file } = this.state;
    const dataFilled = !!file;
    return (
      <div className='toolSection'>
        <div className='toolName'>Импорт показаний {group?.name}</div>
        <FileInput
          properties={{
            accept: '.csv',
          }}
          object={file}
          onChange={(k, value) => this.setState({file: value})}
        />
        <button
          disabled={!dataFilled}
          onClick={this.onImport}
        >Загрузить</button>
      </div>
    )
  }
}

export default (withRouter(ImportReadings));
