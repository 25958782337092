import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import { saveAs } from 'file-saver';

import appConfig from '../config/';
import { sendRequest, triggerEvent } from '../helpers/global.js';

import SearchView from './SearchView';
import ObjectListElem from './ObjectListElem';
import Pagination from './common/Pagination';
import FileInput from './input/FileInput';

import '../sass/components/ObjectListView.scss';

const ITEMS_PER_PAGE = 20;

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.isMobile,
  }
};

class ObjectListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
    this.rowHeights = {};
    this.updateHeightTimeout = null;
  }

  getDefaultState = (props) => {
    const properties = props.properties || appConfig[props.configKey].properties;
    const config = props.config || appConfig[props.configKey].config;
    let search = {};
    // Default search interval
    if (config.search) {
      Object.keys(config.search).forEach((key) => {
        search[key] = config.search[key].default || null;
      });
    }
    this.props.history.location.search.replace(/^\?/, '').split('&').forEach(p => {
      const pair = p.split('=');
      if (pair[0]) {
        let value = pair[1];
        if (value === 'false') {
          value = false;
        }
        if (value === undefined) {
          value = true;
        }
        search[pair[0]] = decodeURIComponent(value);
      }
    });
    const page = (Number(this.props.location.hash.replace(/\D/g, '')) || 1) - 1;
    return {
      objects: [],
      properties,
      config,
      options: {},
      search,
      sort: 'id',
      sort_order: 'desc',
      page,
      maxPage: 0,
      importFile: null,
      rowHeights: {},
      searchOpen: false,
    };
  }

  componentDidMount = () => {
    if (this.props.embedded) {
      this.setState({objects: this.props.object});
    } else {
      if (!this.state.config.search) {
        this.requestData();
      }
    }
    this.requestOptions();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.configKey !== prevProps.configKey) {
      this.setState(this.getDefaultState(this.props), () => {
        if (this.props.embedded) {
          this.setState({objects: this.props.object});
        } else {
          this.requestData();
        }
        this.requestOptions();
      })
    } else if (this.props.embedded) {
      if (this.props.object !== prevProps.object) {
        this.setState({objects: this.props.object});
      }
    }
  }

  requestOptions = () => {
    let options = Object.assign({}, this.state.options);
    Object.keys(this.state.properties).forEach(key => {
      if (this.state.properties[key].width && this.state.properties[key].type === 'select') {
        if (this.state.properties[key].request) {
          sendRequest({
            type: 'GET',
            method: this.state.properties[key].request,
            auth: this.state.properties[key].requestAuth,
            success: (data) => {
              this.setState({options:
                update(this.state.options, {
                  [key]: {$set: data}
                })
              });
            },
            error: (data) => {}
          });
        } else if (this.state.properties[key].options) {
          options[key] = this.state.properties[key].options;
        }
      }
    });
    this.setState({options});
  }

  requestData = () => {
    const search = this.state.search;
    const searchParams = Object.keys(search)
      .filter(key => key && search[key] !== null && search[key] !== undefined && search[key] !== '')
      .map(key => `${key}=${search[key]}`)
      .join('&');
    this.props.history.replace(`${this.props.location.pathname}?${searchParams}#${this.state.page + 1}`);
    sendRequest({
      method: this.state.config.method,
      data: {
        paginate: true,
        offset: this.state.page * ITEMS_PER_PAGE,
        sort: this.state.sort,
        sort_order: this.state.sort_order,
        ...search,
      },
      type: 'GET',
      success: (data) => {
        const page = this.state.page;
        const maxPage = Math.ceil(data.count / ITEMS_PER_PAGE);
        this.setState({
          objects: data.objects,
          maxPage: maxPage,
          page: Math.min(page, maxPage),
        });
      },
      error: (data) => {
      }
    });
  }

  onImport = () => {
    this.setState({importFile: null});
    triggerEvent('showContentPopup', [{
      title: 'Import CSV',
      content: <FileInput
        key={Date.now()}
        onChange={(k, val) => this.setState({importFile: val})}
        properties={{
          accept: '.csv'
        }}
      />,
      buttonText: 'Import',
      callback: result => {
        if (result && this.state.importFile) {
          const formData = new FormData();
          formData.append('file', this.state.importFile);
          sendRequest({
            method: this.state.config.import.method,
            type: 'POST',
            formData,
            success: (data) => {
              triggerEvent('showSnackbar', [{text: 'Записи в обработке', type: 'success'}]);
              this.requestData();
            },
            error: (data) => {
            }
          });
          return true;
        }
      }
    }]);
  }

  requestCsvData = (search) => {
    sendRequest({
      method: this.state.config.exportMethod,
      data: search,
      type: 'GET',
      success: (data) => {
        const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const title = this.state.config.title;
        const titleFormatted = title ? title.toLowerCase().replace(/ /g, '_') : null;
        const fileName = titleFormatted ? `${titleFormatted}.csv` : 'search.csv';
        saveAs(csvData, fileName);
      },
      error: (data) => {
      }
    });
  }

  onObjectDelete = (object, updateModel = false) => {
    if (updateModel) {
      if (this.props.embedded) {
        if (this.props.onChange) {
          const objects = this.state.objects.filter(i => i.id != object.id); // eslint-disable-line eqeqeq
          this.props.onChange(this.props.objectKey, objects);
        }
      } else {
        this.requestData();
      }
      return;
    }
    triggerEvent('showConfirmation', [{
      title: `Вы действительно хотите удалить ${this.state.config.objectName} №${object.id}?`,
      confirmText: 'Удалить',
      cancelText: 'Отмена',
      callback: confirm => {
        if (confirm) {
          this.onDeleteConfirm(object);
        }
      }
    }]);
  }

  onDeleteConfirm = (object) => {
    sendRequest({
      method: this.state.config.method + object.id,
      type: 'DELETE',
      success: (data) => {
        if (this.props.embedded) {
          if (this.props.onChange) {
            const objects = this.state.objects.filter(i => i.id != object.id); // eslint-disable-line eqeqeq
            this.props.onChange(this.props.objectKey, objects);
          }
        } else {
          this.requestData();
        }
      },
      error: (data) => {
      }
    });
  }

  onSearch = (search, initial) => {
    let data = {...this.state.search};
    Object.keys(search).forEach(key => {
      if (search[key] === undefined || search[key] === null) {
        data[key] = null;
      } else {
        data[key] = search[key];
      }
    });
    this.setState({
      page: initial ? this.state.page : 0,
      search: data,
      searchOpen: false,
    }, () => {
      this.requestData();
    });
  }

  onObjectExport = async (object) => {
    sendRequest({
      method: this.state.config.exportItem?.method?.replace(':id', object.id),
      type: 'GET',
      responseType: 'blob',
      success: (data, response) => {
        var fileData = new Blob([data], {
          type: response.headers['content-type'],
        });
        const match = decodeURI(response.headers['content-disposition'])?.match(/filename=(.*)/);
        saveAs(fileData, match?.[1] || Date.now());
      },
      error: (data) => {
      }
    });
  }

  onObjectEdit = (object) => {
    const configKey = this.state.config.editPageKey || this.props.configKey;
    this.props.history.push(`/${configKey}/${object.id}`);
  }

  onObjectCreate = (e) => {
    const configKey = this.state.config.editPageKey || this.props.configKey;
    this.props.history.push(`/${configKey}/create`);
  }

  onObjectChange = (id, key, value) => {
    const index = this.state.objects.findIndex(i => i.id === id);
    this.setState({objects:
      update(this.state.objects, {
        [index]: {
          [key]: {$set: value}
        }
      })
    });
  }

  showAudit = () => {
    triggerEvent('showAudit', [{
      config: this.state.config,
      properties: this.state.properties,
    }]);
  }

  renderListColumn = (key) => {
    const columnProps = this.state.properties[key];
    const order = columnProps.sort && this.state.sort === key ? this.state.sort_order : null;
    return (
      <th
        key={key}
        width={columnProps.width}
        className={columnProps.sort ? 'sortable' : ''}
        onClick={columnProps.sort ? () => {
          this.setState({
            sort: key,
            sort_order: order === 'desc' ? 'asc' : 'desc',
          }, () => this.requestData())
        } : null}
      >
        {this.state.properties[key].title}
        {order ? 
          <div
            className='sortIcon'
            style={{
              transform: order === 'asc' ? 'scaleY(-1)' : null,
            }}
          >
            <span className='material-icons'>sort</span>
          </div>
        : null}
      </th>
    )
  }

  renderPagination = () => {
    if (this.state.maxPage <= 1) {
      return null;
    }
    return (
      <Pagination
        page={this.state.page + 1}
        maxPage={this.state.maxPage}
        onPageChange={page => {
          this.setState({page: page - 1}, this.requestData);
        }}
      />
    )
  }

  renderSearch = () => {
    if (this.state.config.search || this.state.config.exportMethod) {
      return (
        <>
          <div
            className='filterButton'
            onClick={() => this.setState({searchOpen: !this.state.searchOpen})}
          >
            <div className='tooltip top left'>Фильтры</div>
            <span className='material-icons'>filter_list</span>
          </div>
          <div
            className={classnames('searchSidebar', {
              'open': this.state.searchOpen,
            })}
            onClick={e => {
              if (e.target === e.currentTarget) {
                this.setState({searchOpen: false});
              }
            }}
          >
            <SearchView
              initialValue={this.state.search}
              config={this.state.config.search || {}}
              onSearch={this.onSearch}
              onExport={this.state.config.exportMethod ?
                search => this.requestCsvData(search)
              : null}
            />    
          </div>
        </>
      )
    }
    return null;
  }

  renderObjects = () => {
    return (
      this.state.objects.map((object, index) =>
        <ObjectListElem
          key={object.id}
          config={this.state.config}
          properties={this.state.properties}
          onDelete={this.onObjectDelete}
          onEdit={this.onObjectEdit}
          onChange={this.onObjectChange}
          options={this.state.options}
          object={object}
          parentObject={this.props.parentObject}
          elemRef={elem => {
            if (elem) {
              const height = elem.offsetHeight;
              if (this.rowHeights[index] !== height) {
                this.rowHeights = {
                  ...this.rowHeights,
                  [index]: height,
                };
                clearTimeout(this.updateHeightTimeout);
                this.updateHeightTimeout = setTimeout(() => {
                  this.setState({rowHeights: this.rowHeights});
                }, 25);
              }
            }
          }}
        />
      )
    )
  }

  renderControls = (object, index, showEdit, showDelete) => {
    const config = this.state.config;
    let exportButton = config.exportItem ?
      <div
        className='actionButton'
        onClick={() => this.onObjectExport(object)}
        disabled={object.deleted}
      >
        <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.9498 5.96781L15.5356 7.38203L13 4.84646V17.0421H11V4.84653L8.46451 7.38203L7.05029 5.96781L12 1.01807L16.9498 5.96781Z" fill="currentColor"></path>
          <path d="M5 20.9819V10.9819H9V8.98193H3V22.9819H21V8.98193H15V10.9819H19V20.9819H5Z" fill="currentColor"></path>
        </svg>
      </div>
    : null;
    let editButton = <div
      className='actionButton'
      onClick={() => this.onObjectEdit(object)}
      disabled={object.deleted}
    >
      <span className='material-icons'>
        {config.disableEdit ? 'visibility' : 'create'}
      </span>
    </div>;
    let deleteButton = <div
      className='actionButton'
      onClick={() => this.onObjectDelete(object)}
      disabled={object.deleted}
    >
      <span className='material-icons'>delete</span>
    </div>;
    if (config.modifyCondition && !config.modifyCondition(this.state.object)) {
      editButton = <div/>;
      deleteButton = <div/>;
    }
    if (!showEdit) {
      editButton = null;
    }
    if (!showDelete) {
      deleteButton = null;
    }
    const rowHeight = this.state.rowHeights[index];
    return (
      <tr
        key={object.id}
        style={{height: rowHeight ? `${rowHeight}px` : 'auto'}}
      >
        {exportButton ? <td style={{padding: 0}}>{exportButton}</td> : null}
        {editButton ? <td style={{padding: 0}}>{editButton}</td> : null}
        {deleteButton ? <td style={{padding: 0}}>{deleteButton}</td> : null}
      </tr>
    )
  }

  render = () => {
    let config = this.state.config;

    // Objects
    const columns = Object.keys(this.state.properties)
      .filter(key => !!this.state.properties[key].width)
      .filter(key => {
        let condition = this.state.properties[key].listCondition;
        return !condition || condition()
      });

    let editColumn = <th width='46px'/>;
    let deleteColumn = <th width='46px'/>;
    let addButton = <div
      className='createButton'
      onClick={this.onObjectCreate}
    >
      <span className='material-icons'>add</span>
    </div>
    if (config.modifyCondition && !config.modifyCondition()) {
      addButton = null;
      editColumn = null;
      deleteColumn = null;
    }
    if (config.disableAdd) {
      addButton = null;
    }
    if (config.disableEdit && config.disableView) {
      editColumn = null;
    }
    if (config.disableDelete) {
      deleteColumn = null;
    }

    return (
      <div
        className={classnames('objectListView', {
          'embedded': this.props.embedded,
        })}
      >
        {this.props.embedded ? null :
          <div className='listTitle'>
            <label>{config.title}</label>
            {this.state.config.auditableEntity ?
              <div className='auditButton' onClick={this.showAudit}>
                <div className='tooltip top left'>История изменений</div>
                <span className='material-icons'>history</span>
              </div>
            : null}
            {this.renderSearch()}
          </div>
        }

        {this.props.isMobile
          ? this.renderObjects().map((elem, index) =>
            <div key={index} className='card'>{elem}</div>
          )
          : <div className={classnames('tableCard', {'card' : !this.props.embedded})}>
            <div className='tableContainer'>
              <table className='objectListTable'>
                <thead>
                  <tr>
                    {columns.map(this.renderListColumn)}
                  </tr>
                </thead>
                <tbody>
                  {this.renderObjects()}
                </tbody>
              </table>
            </div>
            <table className='objectListTable listControls'>
              <thead>
                <tr>
                  {config.exportItem ? <th width='46px'/> : null}
                  {editColumn}
                  {deleteColumn}
                </tr>
              </thead>
              <tbody>
                {this.state.objects.map((i, index) =>
                  this.renderControls(i, index, !!editColumn, !!deleteColumn)
                )}
              </tbody>
            </table>
          </div>
        }
        <div className='bottomControls'>
          {this.renderPagination()}
          {addButton}
          {config.import ?
            <button className='importButton' onClick={this.onImport}>Import CSV</button>
          : null}
        </div>

      </div>
    );
  }
}

export default connect(mapStoreToProps)(withRouter(ObjectListView));
