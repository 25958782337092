import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { saveAs } from 'file-saver';

import { sendRequest, triggerEvent } from '../helpers/global.js';

import UserAvatarView from './UserAvatarView.js';
import SelectInput from './input/SelectInput.js';
import TextInput from './input/TextInput.js';

import '../sass/components/ObjectListElem.scss';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.isMobile,
  }
};

class ObjectListElem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      object: this.getDefaultObjectState(),
    };
    this.changeTimeout = null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.config !== prevProps.config
      || this.props.properties !== prevProps.properties
      || this.props.object !== prevProps.object
    ) {
      this.setState({
        object: this.getDefaultObjectState()
      })
    }
  }

  getDefaultObjectState = () => {
    let object = {};
    const properties = this.props.properties;
    Object.keys(properties).forEach(key => {
      if ((typeof this.props.object[key] !== 'undefined') && (this.props.object[key] !== null)) {
        object[key] = this.props.object[key];
      } else {
        if (properties[key].default === null) {
          object[key] = null;
        } else if (Array.isArray(properties[key].default)) {
          object[key] = properties[key].default.slice();
        } else if (typeof properties[key].default === 'object') {
          object[key] = Object.assign({}, properties[key].default);
        } else {
          object[key] = properties[key].default;
        }
      }
    });
    return object;
  }

  handleExport = () => {
    sendRequest({
      method: this.props.config.exportItem?.method?.replace(':id', this.props.object.id),
      type: 'GET',
      responseType: 'blob',
      success: (data, response) => {
        var fileData = new Blob([data], {
          type: response.headers['content-type'],
        });
        const match = decodeURI(response.headers['content-disposition'])?.match(/filename=(.*)/);
        saveAs(fileData, match?.[1] || Date.now());
      },
      error: (data) => {
      }
    });
  }
  
  handleEdit = () => {
    this.props.onEdit(this.state.object);
  }

  handleDestroy = () => {
    this.props.onDelete(this.state.object);
  }

  openChatPage = () => {
    const item = this.state.object;
    const tab = window.open();
    if (item.chat_identifier) {
      tab.location = `/chats/${item.chat_identifier}`;
      return;
    }
    sendRequest({
      method: `${this.state.props.method}/${item.id}/chat`,
      type: 'GET',
      success: (data) => {
        tab.location = `/chats/${data.identifier}`;
      },
      error: (data) => {
        tab.close();
      }
    });
  }

  saveChanges = (key, value) => {
    const itemConfig = this.props.properties[key];
    if (itemConfig.method) {
      sendRequest({
        method: itemConfig.method.replace(':id', this.state.object.id),
        type: 'PUT',
        data: {
          [key]: value
        },
        success: (data) => {
          triggerEvent('showSnackbar', [{text: 'Данные сохранены', type: 'success'}]);
        },
        error: (data) => {
        }
      });
    }
  }

  renderActiveIcon = () => {
    return (
      <span
        className='material-icons'
        style={{margin: '-5px 0px', color: '#2E7D32'}}
      >done</span>
    )
  }

  renderInactiveIcon = () => {
    return (
      <span
        className='material-icons'
        style={{margin: '-5px 0px', color: '#cccccc'}}
      >close</span>
    )
  }

  renderPageLink = (key, object) => {
    const properties = this.props.properties[key];
    if (!object || Object.keys(object).length === 0) {
      return (
        <div className='page-link'>{properties.defaultLabel || ''}</div>
      )
    }
    const label = object[properties.field || 'name'];
    return (
      <div
        key={object.id}
        className={label ? 'page-link' : 'actionButton'}
        onClick={() => {
          if (properties.external) {
            if (label) {
              if (object.url) {
                window.open(object.url, '_blank');
              }
            } else {
              window.open(object, '_blank');
            }
          } else {
            if (object.page) {
              this.props.history.push(`/${object.page}/${object.id || ''}`);
            } else {
              this.props.history.push(
                properties.page.replace(':id', object.id || '')
              );
            }
          }
        }}
      >
        {label
          ? <span className={object.url || object.id ? 'clickable' : ''}>{label}</span>
          : <span className='material-icons'>exit_to_app</span>
        }
      </div>
    )
  }

  renderCellContent = (key) => {
    const properties = this.props.properties[key];
    if (!properties.width) {
      return null;
    }
    let content = this.state.object[key];
    if (properties.type === 'avatar') {
      content = <UserAvatarView
        user={this.state.object}
        size={30}
        style={{margin: '-5px 0px'}}
        image={content}
      />
    }
    if (properties.type === 'select' || properties.type === 'detailed-select') {
      if (properties.listEdit) {
        const options = (this.props.options || {})[key] || [];
        content = <SelectInput
          properties={{options, clearable: properties.clearable}}
          object={this.state.object[key]}
          onChange={(k, value) => {
            this.props.onChange(this.state.object.id, key, value);
            this.saveChanges(key, value);
          }}
        />
      } else if (typeof content === 'boolean') {
        content = content ? this.renderActiveIcon() : this.renderInactiveIcon();
      } else {
        const options = (this.props.options || {})[key] || []
        const item = options.find(option => option.id === this.state.object[key]);
        if (item) {
          let displayKey = properties.displayField || 'name';
          content = item[displayKey];
        } else {
          content = '';
        }
      }
    }
    if (properties.type === 'checkbox') {
      content = content ? this.renderActiveIcon() : this.renderInactiveIcon();
    }
    if (properties.type === 'multi') {
      let displayKey = properties.displayField || 'name';
      var labels = content.map((elem) => {
        return elem[displayKey];
      });
      content = labels.join(', ');
    }
    if (properties.type === 'date') {
      if (content) {
        content = moment(content).format('DD.MM.YYYY');
      } else {
        content = '';
      }
    }
    if (properties.type === 'datetime') {
      if (content) {
        content = moment.unix(content).format('DD.MM.YYYY HH:mm');
      } else {
        content = '';
      }
    }
    if (properties.type === 'link-list') {
      const object = this.state.object[key] || [];
      content = <div className='link-list'>
        {object.map(i => this.renderPageLink(key, i))}
      </div>
    }
    if (properties.type === 'page-link') {
      let object = this.state.object[key];
      content = this.renderPageLink(key, object);
    }
    if (properties.type === 'text') {
      content = content === null || content === undefined ? '' : content;
      if (properties.listEdit) {
        content = <TextInput
          properties={{type: properties.type}}
          object={this.state.object[key]}
          onChange={(k, value) => {
            this.props.onChange(this.state.object.id, key, value);
            clearTimeout(this.changeTimeout);
            this.changeTimeout = setTimeout(() => {
              this.saveChanges(key, value);
            }, 500);
          }}
        />
      } else if (properties.onClickList) {
        content = <div
          className='page-link'
          onClick={() => {
            if (properties.onClickList === 'open-edit') {
              this.handleEdit();
            } else if (properties.onClickList === 'open-chat') {
              this.openChatPage();
            }
          }}
        >
          <span className='clickable'>{content}</span>
        </div>
      }
    }
    return content
  }

  render = () => {
    const config = this.props.config;

    let exportButton = config.exportItem ?
      <div
        className='actionButton'
        onClick={this.handleExport}
        disabled={this.state.object.deleted}
      >
        <svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.9498 5.96781L15.5356 7.38203L13 4.84646V17.0421H11V4.84653L8.46451 7.38203L7.05029 5.96781L12 1.01807L16.9498 5.96781Z" fill="currentColor"></path>
          <path d="M5 20.9819V10.9819H9V8.98193H3V22.9819H21V8.98193H15V10.9819H19V20.9819H5Z" fill="currentColor"></path>
        </svg>
      </div>
    : null;
    let editButton = <div
      className='actionButton'
      onClick={this.handleEdit}
      disabled={this.state.object.deleted}
    >
      <span className='material-icons'>
        {config.disableEdit ? 'visibility' : 'create'}
      </span>
    </div>;
    let deleteButton = <div
      className='actionButton'
      onClick={this.handleDestroy}
      disabled={this.state.object.deleted}
    >
      <span className='material-icons'>delete</span>
    </div>;

    if (config.modifyCondition && !config.modifyCondition(this.state.object)) {
      editButton = <div/>;
      deleteButton = <div/>;
    }
    if (config.disableEdit && config.disableView) {
      editButton = null;
    }
    if (config.disableDelete) {
      deleteButton = null;
    }
    if (!this.props.isMobile) {
      exportButton = null;
      editButton = null;
      deleteButton = null;
    }

    if (this.props.isMobile) {
      return <table className='objectListElem'>
        <tbody>
          {Object.keys(this.props.properties).map(key => {
            const content = this.renderCellContent(key);
            if (content === null || content === undefined) {
              return null;
            } else {
              return <tr key={key}>
                <td width='125px'>{this.props.properties[key].title}</td>
                <td width='auto'>{content}</td>
              </tr>
            }
          })}
          <tr>
            <td width='125px'>Действия</td>
            <td width='auto' style={{padding: 0}}>
              {exportButton}
              {editButton}
              {deleteButton}
            </td>
          </tr>
        </tbody>
      </table>
    } else {
      return (
        <tr
          className='objectListElem'
          ref={this.props.elemRef}
        >
          {Object.keys(this.props.properties).map(key => {
            const content = this.renderCellContent(key);
            if (content === null || content === undefined) {
              return null;
            } else {
              return (
                <td
                  key={key}
                  className={content && content.props ? 'control' : ''}
                >{content}</td>
              )
            }
          })}
          {exportButton ? <td style={{padding: 0}}>{exportButton}</td> : null}
          {editButton ? <td style={{padding: 0}}>{editButton}</td> : null}
          {deleteButton ? <td style={{padding: 0}}>{deleteButton}</td> : null}
        </tr>
      );
    }
  }
}

export default connect(mapStoreToProps)(withRouter(ObjectListElem))
